import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import TextButton from "../Components/TextButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function AdditionalInformationForm({ submit, cancel }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const { handleSubmit, control, watch, formState: { errors } , setValue, getValues } = useForm({
    defaultValues: {
      is_interested_poll_worker: '',
      is_interested_interpreter: '',
      interpreter_language: '',
      help_required: '',
      assistance_type: '',
      assistance_language: ''
    }
  });

  const { is_interested_interpreter, help_required, assistance_language, assistance_type } = watch();

  React.useEffect(() => {
    const {
      is_interested_poll_worker,
      is_interested_interpreter,
      interpreter_language,
      help_required,
      assistance_type,
      assistance_language,
    } = registrant;

    setValue('is_interested_poll_worker', is_interested_poll_worker);
    setValue('is_interested_interpreter', is_interested_interpreter);
    setValue('interpreter_language', interpreter_language);
    setValue('help_required', help_required);
    setValue('assistance_type', assistance_type);
    setValue('assistance_language', assistance_language);

  }, [registrant]);

  React.useEffect(() => {
    if (is_interested_interpreter == 'no') {
      setValue('interpreter_language', '');
    }
  }, [is_interested_interpreter]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="px-2">
      <label>{getContent("fields.is_interested_poll_worker")}*</label>

      <div className="flex items-center justify-between py-1">
        <Controller
          name="is_interested_poll_worker"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => <label className="radio" ><input {...field} type="radio" value="yes" defaultChecked={registrant.is_interested_poll_worker == 'yes' ? true : false} /><span></span> <span className="pl-4">{getContent('buttons.yes')}</span></label>}
          />
      </div>

      <div className="flex items-center justify-between py-1 ">
        <Controller
          name="is_interested_poll_worker"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="no" defaultChecked={registrant.is_interested_poll_worker == 'no' ? true : false} /><span></span>  <span className="pl-4">{getContent('buttons.no')}</span></label>}
          />
      </div>
      
      <div className="flex items-center justify-between pb-4">
        {errors.is_interested_poll_worker?.type === 'required' && <span className="error">{getContent('validation.select_option')}</span>}
      </div>


      <label className="mt-4">{getContent('fields.is_interested_interpreter')}*</label>
      
      <div className="flex items-center justify-between py-1 ">
        <Controller
          name="is_interested_interpreter"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="yes" defaultChecked={registrant.is_interested_interpreter == 'yes' ? true : false} /><span></span> <span className="pl-4">{getContent('buttons.yes')}</span></label>}
          />
      </div>

      <div className="flex items-center justify-between py-1 ">
        <Controller
          name="is_interested_interpreter"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="no"   defaultChecked={registrant.is_interested_interpreter == 'no' ? true : false}  /><span></span>  <span className="pl-4">{getContent('buttons.no')}</span></label>}
          />
      </div>

      <div className="flex items-center justify-between px-2">
        {errors.is_interested_interpreter?.type === 'required' && <span className="error">{getContent('validation.select_option')}</span>}
      </div>

      <div className="flex-1 mt-4 ">
        <label>{getContent('fields.interpreter_language')}</label>
        <Controller
            name="interpreter_language"
            control={control}
            rules={{ 
              required: is_interested_interpreter == 'yes' ? true : false
            }}
            render={({ field }) => <input {...field} type="text" maxLength="50" autoComplete="off" className={`${errors.interpreter_language ? 'error' : ''}`} />}
            />
      </div>

      <div className="flex items-center justify-between pb-4">
        {errors.interpreter_language?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
      </div>


      <label>{getContent('required_help.heading')}*</label>

      <div className="flex items-center justify-between py-1">
        <Controller
          name="help_required"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="yes" defaultChecked={registrant.help_required =='yes' ? true : false} /><span></span> <span className="pl-4">{getContent('buttons.yes')}</span></label>}
          />
      </div>

      <div className="flex items-center justify-between py-1 ">
        <Controller
          name="help_required"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="no" defaultChecked={registrant.help_required =='no' ? true : false} /><span></span>  <span className="pl-4">{getContent('buttons.no')}</span></label>}
          />
      </div>

      <div className="flex items-center justify-between ">
        {errors.help_required?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
      </div>

      {help_required == 'yes' && (
        <>
        <div className="flex-1">
          <label>{getContent('required_help.label')}</label>
          <Controller
            name="assistance_type"
            control={control}
            rules={{
              required: (help_required == 'yes') ? true : false
            }}
            render={({ field }) => {
              return (
                <div className={`select ${errors.assistance_type ? 'error' : ''}`} >
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                      {Object.keys(information?.assistanceTypes).map((key, i) => {
                        if (information?.assistanceTypes[key][currentLanguage] != undefined) {
                          return (
                            <option key={i} value={key}>{information?.assistanceTypes[key][currentLanguage]}</option>
                          );
                        }
                      })}
                  </select>
                </div>
              );
            }}
          />
        </div>

        <div className="flex items-center justify-between ">
          {errors.assistance_type?.type === 'required' && <span className="error">{getContent('required_help.type_error')}</span>}
        </div>
        </>
      )}

      {help_required == 'yes' && assistance_type == 'LN' && (
        <>
          <div className="flex-1">
            <label>{getContent('required_help.language_label')}</label>
            <Controller
              name="assistance_language"
              control={control}
              rules={{
                required: (help_required == 'yes' && assistance_type == 'LN') ? true : false
              }}
              render={({ field }) => <input {...field} placeholder="English" type="text" maxLength="50" autoComplete="off" className={`${errors.assistance_language ? 'error' : ''}`}/>}
            />
          </div>
          
          <div className="flex items-center justify-between ">
            {errors.assistance_language?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
          </div>
        </>
      )}

      <div className="flex justify-end pt-8 pb-2">
        <TextButton onClick={cancel} className="pr-2">{getContent('buttons.cancel')}</TextButton>
        <PrimaryButton submit>{getContent('buttons.update_information')}</PrimaryButton>
      </div>
    </form>
  );
}

export default AdditionalInformationForm;
