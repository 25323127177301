import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { isValidZip, isValidPhoneNumber } from '../helpers';
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function MailingAdressForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);

  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    defaultValues: {
      assistant_name: '',
      assistant_address_1: '',
      assistant_city: '',
      assistant_state: '',
      assistant_zipcode: '',
      assistant_phone_number: '',
    }
  });

  const onSubmit = data => submit(data);

  React.useEffect(() => {
    const {
      assistant_name,
      assistant_address_1,
      assistant_city,
      assistant_state,
      assistant_zipcode,
      assistant_phone_number,
    } = registrant;

    setValue('assistant_name', assistant_name);
    setValue('assistant_address_1', assistant_address_1);
    setValue('assistant_city', assistant_city);
    setValue('assistant_state', assistant_state);
    setValue('assistant_zipcode', assistant_zipcode);
    setValue('assistant_phone_number', assistant_phone_number);
  }, [registrant]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-1 p-2">
        <label>{getContent('fields.assistant_name')}*</label>
        <Controller
          name="assistant_name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <input {...field} placeholder="Jane Doe" type="text" autoComplete="off" maxLength="100" className={`${errors.assistant_name ? 'error' : ''}`} />}
        />
        {errors.assistant_name?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
      </div>
      <div className="flex-1 p-2">
        <label>{getContent('fields.street_address')}*</label>
        <Controller
          name="assistant_address_1"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="53" className={`${errors.assistant_address_1 ? 'error' : ''}`} />}
        />
        {errors.assistant_address_1?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
      </div>

      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.city')}*</label>
          <Controller
            name="assistant_city"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <input {...field} placeholder="City" type="text" autoComplete="off" maxLength="35" className={`${errors.assistant_city ? 'error' : ''}`} />}
          />
          {errors.assistant_city?.type === 'required' && <span className="error">{getContent('validation.city')}</span>}

        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.state')}*</label>
          <Controller
            name="assistant_state"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <div className={`select ${errors.assistant_state ? 'error' : ''}`} >
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                    {Object.keys(information?.states).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.states[key]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
          {errors.assistant_state?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
        </div>

      </div>

      <div className="flex-1 p-2">
        <label>{getContent('fields.zipcode')}*</label>
        <Controller
          name="assistant_zipcode"
          control={control}
          rules={{
            required: true,
            maxlength: 5,
            validate: {
              zipcode: value => isValidZip(value) || 'Valid zipcode is required'
            }
          }}
          render={({ field }) => {
            return (
              <InputMask placeholder="12345" className={`input ${errors.assistant_zipcode ? 'error' : ''}`} {...field} mask="99999" />
            )
          }}
        />
        {errors.assistant_zipcode?.type === 'required' && <span className="error">{getContent('validation.zipcode')}</span>}
        {errors.assistant_zipcode?.type === 'zipcode' && <span className="error">{getContent('validation.zipcode_valid')}</span>}
      </div>

      <div className="flex-1 p-2">
        <label>{getContent('fields.phone_number')}*</label>
        <Controller
          name="assistant_phone_number"
          control={control}
          rules={{
            required: true,
            validate: {
              phone: value => !value || (value && isValidPhoneNumber(value) || getContent('validation.phone_number_valid'))
            }
          }}
          render={({ field }) => {
            return (
              <InputMask placeholder="555-555-5555" className={`input ${errors.assistant_phone_number ? 'error' : ''}`} {...field} mask="999-999-9999" />
            );
          }}
        />
        {errors.assistant_phone_number?.type === 'required' && <span className="error">{getContent('validation.phone_number_valid')}</span>}
        {errors.assistant_phone_number?.type === 'phone' && <span className="error">{getContent('validation.phone_number_valid')}</span>}
      </div>

      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default MailingAdressForm;