import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BackButton from "../Components/BackButton";
import SurveyQuestionForm from "../Forms/SurveyQuestionForm";
import { useDispatch } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function SurveyQuestion() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const partner = useSelector((state) => state.partner);
    const registrant = useSelector((state) => state.registrant);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const {state} = useLocation();

    React.useEffect(() => {
        dispatch(changeStep([5, 6]));
    }, []);

    const getTitle = (id) => {
        let question;

        if (id === 'question_01') {
            question = partner['survey_question_01'];
        } else if (id === 'question_02') {
            question = partner['survey_question_02'];
        } else if (id === 'question_03') {
            question = partner['survey_question_03'];
        }

        return (question['title_'+currentLanguage] || question['title_en']);
    };

    const onSubmit = (data) => {
        Object.keys(data).forEach(key => data[key] === undefined ? delete data[key] : {});

        dispatch(updateRegistrant({
            ...data
        }));

        if (state.question === 'question_01' && partner['survey_question_02']['enabled']) {
            navigate('/survey-question', { state: { question: 'question_02' } });
        } else if ((state.question === 'question_01' || state.question === 'question_02') && partner['survey_question_03']['enabled']) {
            navigate('/survey-question', { state: { question: 'question_03' } });
        } else {
            navigate('/review-information');
        }
    };

    return (
        <section className="lg:grid grid-cols-12 gap-4">
            <div className="col-start-1 col-span-4">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:my-8 lg:mt-16">{getTitle(state.question)}</h2>
            </div>
            <div className="col-start-6 col-span-7">
                <div className="border-4 border-pvr-darkblue rounded-lg p-8">
                    <SurveyQuestionForm question_id={state.question} submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default SurveyQuestion;