import React from "react";
import HeaderLogo from "./HeaderLogo";
import ProgressBar from "./ProgressBar";
import { useSelector, useDispatch } from 'react-redux';
import { changeLanguage } from "../store/settings/settingsSlice";

function Header() {
  const dispatch = useDispatch();
  const partner = useSelector((state) => state.partner);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  return (
    <header>
      <div className="container mx-auto flex justify-between items-center p-2 sm:px-4 md:px-2 md:py-4">
        {partner['logo_image'] !== null ? (
          <div className="leading-none">
            <a href="/">
              <img className="max-w-xs lg:max-w-sm max-h-32 w-full h-full hidden md:block" src={partner['logo_image']} alt={partner['name']} />
              <img className="w-16 sm:w-20 md:hidden" src={partner['logo_image']} alt={partner['name']} />
            </a>
          </div>
        ) : (
          <HeaderLogo />  
        )}
        <div>
          <div className="select w-52"> 
            <select value={currentLanguage} onChange={event => dispatch(changeLanguage(event.target.value))}>
              <option value="en">English</option>
              <option value="es">Español</option>
              <option value="zh">漢語</option>
              {/*<option value="vi">Tiếng Việt</option>*/}
            </select>
          </div>
        </div>
      </div>
      <ProgressBar />
    </header>
  );
}

export default Header;