import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BackButton from "../Components/BackButton";
import RegistrationForm from "../Forms/RegistrationForm";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrant, clearCurrentRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { getContent } from "../content";

function Registration() {
    const registrant = useSelector((state) => state.registrant);
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    React.useEffect(() => {
        dispatch(changeStep([1, 6]));
        
        if (location.state?.resetRegistration) {
            dispatch(clearCurrentRegistrant());
            dispatch(updateRegistrant({
                registered: false,
            }));
        }
    }, []);

    const onSubmit = (data) => {
        Object.keys(data).forEach(key => data[key] === undefined ? delete data[key] : {});

        dispatch(updateRegistrant({
            ...data
        }));

        if (registrant.registered) {
            navigate("/update-information");
        } else {
            navigate("/eligibility-notice");
        }

    };

    return (
        <section className="lg:grid grid-cols-12 gap-4">
            <div className="col-start-1 col-span-4">
                <BackButton onClick={() => navigate(-1)} />
                {!registrant.registered &&
                    <>
                        <h2 className="my-4 md:my-8 lg:my-16">{getContent('registration.heading_1')}</h2>
                    </>
                }
                {registrant.registered &&
                    <>
                        <h2 className="my-4 md:my-8 lg:mt-16">{getContent('registration.heading_2')}</h2>
                        <p>{getContent('registration.content')}</p>
                    </>
                }
            </div>
            <div className="col-start-6 col-span-7">
                <div className="border-4 border-pvr-darkblue rounded-lg p-4 sm:p-8">
                    <RegistrationForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default Registration;