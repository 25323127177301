import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function PartyAffiliationForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm();

  React.useEffect(() => {
    const {
      party_affiliation,
      party_affiliation_other
    } = registrant;

    setValue('party_affiliation', party_affiliation);
    setValue('party_affiliation_other', party_affiliation_other);
}, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {Object.keys(information?.politicalParties).map((key, i) => {
        return (
          <div className="flex justify-between p-2 items-center ">
            <Controller
              name="party_affiliation"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => <label className="radio"><input {...field} type="radio" value={key} defaultChecked={registrant.party_affiliation == key ? true : false} /><span></span> <span className="pl-4">{information?.politicalParties[key][currentLanguage]}</span></label>}
            />
          </div>
        );
      })}

      <div className="flex-1 p-2">
        <label>{getContent('fields.party_other')}</label>
        <Controller
          name="party_affiliation_other"
          control={control}
          rules={{ 
            validate: {
              other_check: value => {
                return (((getValues('party_affiliation') === 'OTH' && value !== '') || getValues('party_affiliation') !== 'OTH') ? true : false) || getContent('validation.party_other');
              }
            }
          }}
          render={({ field }) => <input {...field} placeholder={getContent('placeholders.political_party')} type="text" autoComplete="off" maxLength="50" className={`${errors.party_affiliation_other ? 'error' : ''}`} />}
        />
      </div>

      <div className="flex justify-between items-center ">
        {(errors.party_affiliation?.type === 'required') && <span className="error">{getContent('validation.party')}</span>}
        {(errors.party_affiliation_other?.type === 'other_check') && <span className="error">{getContent('validation.party_other')}</span>}
      </div>

      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default PartyAffiliationForm;