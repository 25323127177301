import React from "react";
import { useNavigate } from "react-router-dom";
import IDVerificationForm from "../Forms/IDVerificationForm";
import { useDispatch } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import BackButton from "../Components/BackButton";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { getContent } from "../content";

function IDVerification() {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeStep([3, 6]));
    }, []);

    const onSubmit = (data) => {
        if (data.id_type === 'no_id_ssn') {
            data['pa_id_number'] = '';
            data['last_four_ssn'] = '';
        }

        dispatch(updateRegistrant({
            ...data
        }));

        if ( data.id_type === 'not_on_person') {
            navigate('/not-eligible');
        }
        else if (data.id_type === 'no_id_ssn') {
            navigate('/address-information');
        } 
        else if (data.id_type === 'have_ssn') {
            navigate('/last-four-entry');
        } else {
            navigate('/id-entry');
        }
    };

    return (
        <section className="grid-cols-12 gap-4 lg:grid">
            <div className="col-span-4 col-start-1">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:mt-8 lg:mt-16">{getContent('id_verification.heading')}</h2>
                <p>{getContent('id_verification.content')}</p>
            </div>
            <div className="col-span-7 col-start-6">
                <div className="p-8 border-4 rounded-lg border-pvr-darkblue">
                    <IDVerificationForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default IDVerification;