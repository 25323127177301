import React from "react";
import TextButton from "../Components/TextButton";
import { useNavigate } from "react-router-dom";
import BackButton from "../Components/BackButton";
import { getContent } from "../content";

function TermsConditions() {
    let navigate = useNavigate();
    return (
        <div>
            <BackButton onClick={() => navigate(-1)} />
            <section className="flex flex-col justify-center h-full">
                <div className="w-full text-center">
                    <h2 className="max-w-xl my-4 mx-auto">{getContent('terms.heading')}</h2>
                    <div className="text-left">{getContent('terms.content')}</div>
                </div>
            </section>
        </div>
    );
}

export default TermsConditions;