import React from "react";
import { useNavigate } from "react-router-dom";
import AdditionalQuestionsForm from "../Forms/AdditionalQuestionsForm";
import { useDispatch } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import BackButton from "../Components/BackButton";
import { getContent } from "../content";

function AdditionalQuestions() {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeStep([4, 6]));
    }, []);

    const onSubmit = (data) => {
        dispatch(updateRegistrant({
            is_interested_poll_worker: data.is_interested_poll_worker,
            is_interested_interpreter: data.is_interested_interpreter,
            interpreter_language: data.interpreter_language
        }));

        navigate('/required-help');
    };

    return (
        <section className="lg:grid grid-cols-12 gap-4">
            <div className="col-start-1 col-span-4">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:mt-8 lg:mt-16">{getContent('additional_questions.heading')}</h2>
                <p>{getContent('additional_questions.content')}</p>
            </div>
            <div className="col-start-6 col-span-7">
                <div className="border-4 border-pvr-darkblue rounded-lg p-8">
                    <AdditionalQuestionsForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default AdditionalQuestions;

// const content = {
//     ['en-US']: {
//         heading: "Would you like to be a poll worker on Election Day?",
//         content: "Please indicate your preference below.",
//     },
//     ['es-ES']: {
//         heading: "ES - Would you like to be a poll worker on Election Day?",
//         content: "ES - Please indicate your preference below.",
//     }
// }  
