import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { isValidName } from '../helpers';
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function NewNameForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    defaultValues: {
      last_name: '',
      first_name: '',
      middle_name: '',
      suffix: '',
    }
  });

  React.useEffect(() => {
    const {
        first_name,
        last_name,
        middle_name,
        suffix,
    } = registrant;

    setValue('first_name', first_name);
    setValue('last_name', last_name);
    setValue('middle_name', middle_name);
    setValue('suffix', suffix);
}, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex ">
        <div className="flex-1 p-2">
          <label>{getContent('fields.first_name')}*</label>
          <Controller
            name="first_name"
            control={control}
            rules={{
              required: true,
              validate: {
                name: value => !value || (value && isValidName(value) || getContent('validation.first_name'))
              }
            }}
            render={({ field }) => <input {...field} placeholder="Jane" type="text" autoComplete="off" maxLength="30" className={`${errors.first_name ? 'error' : ''}`} />}
          />
          {errors.first_name?.type === 'required' && <span className="error">{getContent('validation.first_name')}</span>}
          {errors.first_name?.type === 'name' && <span className="error">{getContent('validation.first_name')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.last_name')}*</label>
          <Controller
            name="last_name"
            control={control}
            rules={{
              required: true,
              validate: {
                name: value => !value || (value && isValidName(value) || getContent('validation.last_name'))
              }
            }}
            render={({ field }) => <input {...field} placeholder="Doe" type="text" autoComplete="off" maxLength="30" className={`${errors.last_name ? 'error' : ''}`} />}
          />
          {errors.last_name?.type === 'required' && <span className="error">{getContent('validation.last_name')}</span>}
          {errors.last_name?.type === 'name' && <span className="error">{getContent('validation.last_name')}</span>}
        </div>
      </div>
      <div className="flex ">
        <div className="flex-1 p-2">
          <label>{getContent('fields.middle_name')}</label>
          <Controller
            name="middle_name"
            control={control}
            rules={{
              validate: {
                name: value => !value || (value && isValidName(value) || getContent('validation.middle_name'))
              }
            }}
            render={({ field }) => <input {...field} placeholder="Henry" type="text" autoComplete="off" maxLength="30" className={`${errors.middle_name ? 'error' : ''}`} />}
          />
          {errors.middle_name?.type === 'name' && <span className="error">{getContent('validation.middle_name')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.suffix')}</label>
          <Controller
            name="suffix"
            control={control}
            render={({ field }) => {
              return (
                <div className="select">
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                    {Object.keys(information?.suffixes).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.suffixes[key][currentLanguage]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
        </div>
      </div>
    


      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default NewNameForm;