export const isEmpty = (value) => {
    if (value === undefined) return true;
    if (!value) return true;
    if (typeof value == 'string' && value.length <= 0) return true;
    return false;
};

export const isValidName = (value) => {
    if (value.match(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/) == null) {
        return false;
    } else {
        return true;
    } 
};

export const isValidPhoneNumber = (value) => {
    if (value.match(/^[2-9][0-9]{2}\-[2-9][0-9]{2}\-[0-9]{4}$/) == null) {
        return false;
    } else {
        return true;
    }
};

export const isValidEmail = (value) => {
    if (value.match(/^([\-\.\+_a-zA-Z0-9]+@[\-\.\+_a-zA-Z0-9]+\.([a-zA-Z0-9]{2,9})*)$/) == null) {
        return false;
    } else {
        return true;
    } 
};

export const isValidDate = (value) => {
    if (value.match(/^02\/(?:[01]\d|2\d)\/(?:19|20)(?:0[048]|[13579][26]|[2468][048])|(?:0[13578]|10|12)\/(?:[0-2]\d|3[01])\/(?:19|20)\d{2}|(?:0[469]|11)\/(?:[0-2]\d|30)\/(?:19|20)\d{2}|02\/(?:[0-1]\d|2[0-8])\/(?:19|20)\d{2}$/) == null) {
        return false;
    } else {
        return true;
    }
};

export const isValidZip = (value) => {
    value = value.replace(/\-\_*$/g, '');
    if (value.match(/^\d{5}(?:[- ]?\d{4})?$/) == null) {
        return false;
    } else {
        return true;
    }
};

export const isValidAge = (value) => {
    let parts = value.split('/');
    let dob = new Date(parts[2], parts[0] - 1, parts[1]);
    let today = new Date();
    let age = Math.floor((today - dob) / 31557600000);  // milliseconds in year

    if (age < 17) {
        return false;
    } else {
        return true;
    }
};

export const isValidIDNumber = (value) => {
    if (value !== null && value !== '') {
        if (value.replaceAll('-', '').match(/^[0-9]{2}\s[0-9]{3}\s[0-9]{3}$/) == null || value.replaceAll('-', '') === '00 000 000') {
            return false;
        } else {
            return true;
        }
    } else {
        return true;
    }
};

export const isValidRegex = (value, regex) => {
    if (regex !== null && regex !== '' && value.match(regex) === null) {
        return false;
    } else {
        return true;
    }
};

export const getLabel = (id, items, language) => {
    return id ? items[id][language] || 'n/a' : 'n/a';
};

export const getCountyName = (id, counties) => {
    // return counties?.length > 0 ? counties[id]['name'] || 'n/a' : 'n/a';
    // return counties[id]['name'] || 'n/a';
    return counties[id] ? counties[id]['name'] : 'n/a';
};

export const getMunicipalityName = (id, county_id, counties) => {
    // return Object.keys(counties).length > 0 ? (counties[county_id] && counties[county_id]['municipalities'] && counties[county_id]['municipalities'][id]) || 'n/a' : 'n/a';
    // return counties[county_id]['municipalities'][id] || 'n/a';
    return counties[county_id] ? counties[county_id]['municipalities'][id] : 'n/a';
};

