import React from "react";
import { Link } from "react-router-dom";
import TextButton from "../Components/TextButton";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { isValidZip } from '../helpers';
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function PreviousAddressForm({ submit, submitText, cancel }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);

  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    defaultValues: {
      previous_address_1: '',
      previous_city: '',
      previous_state: '',
      previous_zipcode: '',
      previous_county: '',
    }
  });

  const onSubmit = data => submit(data);

  React.useEffect(() => {
    const {
      previous_address_1,
      previous_city,
      previous_state,
      previous_zipcode,
      previous_county,
    } = registrant;

    setValue('previous_address_1', previous_address_1);
    setValue('previous_city', previous_city);
    setValue('previous_state', previous_state);
    setValue('previous_county', previous_county);
    setValue('previous_zipcode', previous_zipcode);
  }, [registrant]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className="flex-1 p-2">
        <label>{getContent('fields.previous_address')}*</label>
        <Controller
          name="previous_address_1"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="100" className={`${errors.previous_address_1 ? 'error' : ''}`} />}
        />
        {errors.previous_address_1?.type === 'required' && <span className="error">{getContent('validation.previous_address')}</span>}
      </div>

      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.previous_city')}*</label>
          <Controller
            name="previous_city"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <input {...field} placeholder="City" type="text" autoComplete="off" maxLength="30" className={`${errors.previous_city ? 'error' : ''}`} />}
          />
          {errors.previous_city?.type === 'required' && <span className="error">{getContent('validation.previous_city')}</span>}

        </div>


        <div className="flex-1 p-2">
          <label>{getContent('fields.previous_state')}*</label>
          <Controller
            name="previous_state"
            control={control}
            rules={{ 
              required: true,
              validate: {
                previous_state: value => {
                  return (value === 'PA') || getContent('validation.previous_state')
                }
              }
            }}
            render={({ field }) => {
              return (
                <div className={`select ${errors.previous_state ? 'error' : ''}`} >
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                    {Object.keys(information?.states).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.states[key]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
          {errors.previous_state?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
          {errors.previous_state?.type === 'previous_state' && <span className="error">{getContent('validation.previous_state')}</span>}
        </div>

      </div>

      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.previous_county')}*</label>
          <Controller
            name="previous_county"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <div className={`select ${errors.previous_county ? 'error' : ''}`} >
                  <select {...field} onChange={(e) => { field.onChange(e.target.value); }}>
                    {Object.keys(information?.counties).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.counties[key].name}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
          {errors.previous_county?.type === 'required' && <span className="error">{getContent('validation.previous_county')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.previous_zipcode')}*</label>
          <Controller
            name="previous_zipcode"
            control={control}
            rules={{
              required: true,
              maxlength: 10,
              validate: {
                previous_zipcode: value => isValidZip(value) || getContent('validation.previous_zipcode')
              }
            }}
            render={({ field }) => {
              return (
                <InputMask placeholder="12345" className={`input ${errors.previous_zipcode ? 'error' : ''}`} {...field} mask="99999" />
              )
            }}
          />
          {errors.previous_zipcode?.type === 'required' && <span className="error">{getContent('validation.previous_zipcode_required')}</span>}
          {errors.previous_zipcode?.type === 'previous_zipcode' && <span className="error">{getContent('validation.previous_zipcode')}</span>}
        </div>


  
      </div>
      <div className="flex justify-end pt-8 pb-2">
          <TextButton onClick={cancel} className="pr-2">{getContent('buttons.cancel')}</TextButton>
          <PrimaryButton submit>{ !submitText ? getContent('buttons.next_step') : submitText }</PrimaryButton>
        </div>
    </form>
  );
}

export default PreviousAddressForm;