import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function HasMailingAddressForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);

  const { handleSubmit, control, formState: { errors } , setValue } = useForm({
    has_mailing_address: '',
  });

  React.useEffect(() => {
    const {
        has_mailing_address,
    } = registrant;

    setValue('has_mailing_address', has_mailing_address);
}, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex justify-between p-2 items-center ">
        <Controller
          name="has_mailing_address"
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="no" defaultChecked={registrant.has_mailing_address == 'no' ? true : false}/><span></span> <span className="pl-4">{getContent('has_mailing_address.mailing_ask_yes')}</span></label>}
        />
      </div>
      <div className="flex justify-between p-2 items-center ">
        <Controller
          name="has_mailing_address"
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="yes" defaultChecked={registrant.has_mailing_address == 'yes' ? true : false} /><span></span>  <span className="pl-4">{getContent('has_mailing_address.mailing_ask_no')}</span></label>}
        />
      </div>
      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default HasMailingAddressForm;