import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { clearCurrentRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import splash from '../img/splash.svg';
import { getContent } from "../content";

function Confirmation() {
    let navigate = useNavigate();
    const dispatch= useDispatch();
    const registrant = useSelector((state) => state.registrant);

    React.useEffect(() => {
        dispatch(changeStep([6, 6]));
        document.getElementById('content').classList.add('splash-bkgd');

        if (window.fbq !== undefined) {
            window.fbq('track', 'PageView');
            window.fbq('track', 'CompleteRegistration');
        }

        if (window.gtag !== undefined) {
            window.gtag('event', 'conversion', {'send_to': 'AW-16490743999/nJBzCICE96AZEL-Rs7c9'});
        }
        
        window.onpopstate = function () {
            window.location.href = '/'; 
        };
    }, []);

    const nextStep = () => {
        dispatch(clearCurrentRegistrant());
        navigate("/");
    };

    return (
        <>
            <section className="flex flex-col justify-center h-full text-center">
                <div className="max-w-xl mx-auto">
                    <h2 className="large-text">{getContent('confirmation.heading')}</h2>
                    <p className="my-4">{getContent('confirmation.content')}</p>
                    {registrant?.email_address !== '' && (
                        <p className="my-4">{getContent('confirmation.content_with_email')}</p>
                    )}
                    <div className="my-6">{getContent('confirmation.support')}</div>
                </div>
                <img className="block max-w-xl mx-auto" src={splash} alt="" />
                <div className="flex justify-center mt-6">
                    <PrimaryButton className="max-w-xs md:m-4" onClick={() => nextStep()} large>{getContent('buttons.start_new_registration')}</PrimaryButton>
                </div>
            </section>
        </>
    );
}

export default Confirmation;