import React from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../Components/BackButton";
import NewNameForm from "../Forms/NewNameForm";
import { useDispatch } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function NewName() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const registrant = useSelector((state) => state.registrant);

    React.useEffect(() => {
        dispatch(changeStep([4, 6]));
    }, []);

    const onSubmit = (data) => {
        Object.keys(data).forEach(key => data[key] === undefined ? delete data[key] : {});

        dispatch(updateRegistrant({
            ...data
        }));

        if (registrant.change_address) {
            navigate('/previous-address');
        }
        else {
            navigate('/has-mailing-address');
        }  
    };

    return (
        <section className="lg:grid grid-cols-12 gap-4">
            <div className="col-start-1 col-span-4">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:my-8 lg:mt-16">{getContent('new_name.heading')}</h2>
            </div>
            <div className="col-start-6 col-span-7">
                <div className="border-4 border-pvr-darkblue rounded-lg p-8">
                    <NewNameForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default NewName;