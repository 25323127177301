import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import PrimaryButton from "../Components/PrimaryButton";
import TextButton from "../Components/TextButton";
import SignaturePad from "react-signature-canvas";
import Modal from 'react-modal';
import edit from '../img/edit.svg';
import { useForm, Controller } from "react-hook-form";
import BackButton from "../Components/BackButton";
import { useSelector } from 'react-redux';
import { generateRegistrationID, createFormPayload, createImagePayload, sendSurveyData, getPublicKey } from '../services/api/voter';
import Bugsnag from '@bugsnag/js';
import { getContent } from "../content";
import Resizer from "react-image-file-resizer";
import dataURLtoBlob from 'blueimp-canvas-to-blob';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function AssistanceAddSignature() {
    const partner = useSelector((state) => state.partner);
    const registrant = useSelector((state) => state.registrant);
    const tracking = useSelector((state) => state.tracking);
    const information = useSelector((state) => state.information);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);

    let navigate = useNavigate();
    const [signature, setSignature] = React.useState(null);
    const [signatureHD, setSignatureHD] = React.useState(null);
    const dispatch = useDispatch();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    let sigPad = React.useRef();

    const { handleSubmit, control, formState: { errors }, watch } = useForm();

    const watchConfirmTerms = watch('confirm_terms');

    React.useEffect(() => {
        dispatch(changeStep([5, 6]));
    }, []);

    React.useEffect(() => {
        const {
            signature
        } = registrant;

        setSignature(signature);
    }, [registrant]);

    const handleSignatureConfirm = async () => {
        if (!sigPad.isEmpty()) {
            let file = b64toFile(sigPad.getCanvas().toDataURL("image/png"));
            setSignatureHD(sigPad.getCanvas().toDataURL("image/png").replace("data:image/png;base64,", ""));

            let resized = await getResizedImage(file);

            setSignature(
                resized.replace("data:image/png;base64,", "")
            );

            closeModal();
        }
    };

    const getResizedImage = (file) => {
        return new Promise(( resolve ) => {
            Resizer.imageFileResizer(
                file,
                180,
                60,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'base64'
            );
        });
    };

    const b64toByteArrays = (b64Data, contentType) => {
        contentType = contentType || "image/png";
        var sliceSize = 512;
    
        var byteCharacters = atob(
          b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, "")
        );
        var byteArrays = [];
    
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
    
          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
    
          var byteArray = new Uint8Array(byteNumbers);
    
          byteArrays.push(byteArray);
        }
        return byteArrays;
    }

    const b64toFile = (b64Data, fileName, contentType) => {
        const byteArrays = b64toByteArrays(b64Data, contentType);
        const file = new File(byteArrays, fileName, { type: contentType, lastModified: new Date() });
        return file;
    }

    const clearSignature = () => {
        sigPad.clear();
    };

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }

    const addSignature = () => {
        openModal();
    };

    const nextStep = async () => {
        dispatch(updateRegistrant({
            confirm_terms_assistant: true,
            assistant_signature_data: signature,
            assistant_signature_data_hd: signatureHD
        }));

        setIsSubmitting(true);

        try {
            let formPayload = {
                ...registrant,
                confirm_terms_assistant: true,
            };

            const responseID = generateRegistrationID();
            const pubKey = await getPublicKey();

            const encryptedResult = createFormPayload(formPayload, tracking, "registration_online", currentLanguage, pubKey.public_key);
            const encryptedSignature = createImagePayload(formPayload.signature_data, 'sig', pubKey.public_key);
            const encryptedSignatureHD = createImagePayload(formPayload.signature_data_hd, 'sig_hd', pubKey.public_key);
            const encryptedSignatureAssist = createImagePayload(signatureHD, 'assist', pubKey.public_key);

            let formRequest = sendSurveyData(responseID, null, partner.urlID, partner.suburlID, "form", encryptedResult.key, encryptedResult.payload).then(() => {
                return true;
            }).catch((error) => {
                return false;
            });
      
            let signatureRequest = sendSurveyData(responseID, null, partner.urlID, partner.suburlID, "sig", encryptedSignature.key, encryptedSignature.payload).then(() => {
                return true;
            }).catch((error) => {
                return false;
            });

            let signatureHDRequest = sendSurveyData(responseID, null, partner.urlID, partner.suburlID, "sig_hd", encryptedSignatureHD.key, encryptedSignatureHD.payload).then(() => {
                return true;
            }).catch((error) => {
                return false;
            });

            let assistantRequest = sendSurveyData(responseID, null, partner.urlID, partner.suburlID, "assist", encryptedSignatureAssist.key, encryptedSignatureAssist.payload).then(() => {
                return true;
            }).catch((error) => {
                return false;
            });

            const result = await Promise.all([formRequest, signatureRequest, signatureHDRequest, assistantRequest]);
            setIsSubmitting(false);
            
            if (result.includes(false)) {
                alert('An error occurred while saving your registration. Please try again.');
            } else {
                navigate('/confirmation');
            }
        } catch (e) {
            Bugsnag.notify(e);
            alert("An error occurred while submitting the form.  Please try again.");
            
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <section className="grid-cols-12 gap-4 lg:grid">
                <div className="col-span-4 col-start-1">
                    <BackButton onClick={() => navigate(-1)} />
                    <h2 className="my-4 md:mt-8 lg:mb-16">{getContent('assistance_add_signature.heading')}</h2>
                </div>
                <div className="col-span-7 col-start-6">
                    <div className="relative flex flex-col items-center justify-center w-full mx-auto mb-6 overflow-hidden border-4 rounded-lg h-34 border-pvr-darkblue">
                        <button type="button" onClick={() => navigate('/assistance-information')} className="absolute block top-4 right-4">
                            <img src={edit} className="w-6 h-6" alt="edit" />
                        </button>
                        <div className="p-8 text-center">
                            <span className="block text-lg text-black uppercase font-GilroyExtraBold">{registrant.assistant_name}</span>
                            <span className="block text-lg text-black uppercase font-GilroyExtraBold">{registrant.assistant_address_1}</span>
                            <span className="block text-lg leading-normal text-black uppercase font-GilroyExtraBold">{registrant.assistant_city}, {registrant.assistant_state} {registrant.assistant_zipcode}</span>
                        </div>
                    </div>
                    <div className="p-8 border-4 rounded-lg border-pvr-darkblue" dangerouslySetInnerHTML={{ __html: information.assistanceDeclaration[currentLanguage] }}>
                    </div>

                    {signature &&
                        <>
                            <div>
                                <h4 className="mt-6 text-center uppercase text-pvr-orange">
                                    {getContent('add_signature.review_your_signature')}
                                </h4>
                                <div className="relative flex flex-col items-center justify-center w-full mx-auto mt-6 overflow-hidden border-4 rounded-lg h-34 border-pvr-darkblue">
                                    <button type="button" onClick={openModal} className="absolute block top-4 right-4">
                                        <img src={edit} className="w-6 h-6" alt="edit" />
                                    </button>
                                    <img
                                        className="block mx-auto"
                                        src={`data:image/png;base64,${signature}`}
                                        width="360"
                                        height="120"
                                        alt="Signature"
                                    />
                                </div>
                            </div>
                            <div className="flex px-2 pt-4 pb-2">
                                <Controller
                                    name="confirm_terms"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" /><span></span> <span>{getContent('add_signature.review_signature_terms_confirm')}</span></label>}
                                />
                            </div>
                            {errors.confirm_terms?.type === 'required' && <span className="error">{getContent('validation.signature_confirmation_message')}</span>}
                            <div className="flex items-center justify-end pt-8 pb-2">
                                <PrimaryButton disabled={!watchConfirmTerms || isSubmitting} className="ml-6" onClick={handleSubmit(nextStep)}>{getContent('buttons.complete_registration')}</PrimaryButton>
                            </div>
                        </>
                    }
                    {!signature &&
                        <div className="flex justify-end pt-8 pb-2">
                            <PrimaryButton onClick={addSignature}>{getContent('buttons.add_signature')}</PrimaryButton>
                        </div>
                    }
                </div>
            </section>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="mt-6 text-center">
                    <h4>{getContent('assistance_add_signature.review_signature_sign')}</h4>
                    <div className="w-full mx-auto mt-6 overflow-hidden border-4 rounded-lg h-30 border-pvr-darkblue">
                        <div className="sigContainer">
                            <SignaturePad
                                canvasProps={{ className: "sigPad" }}
                                ref={(ref) => (sigPad = ref)}
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-end pt-8 pb-2">
                        <TextButton onClick={clearSignature}>{getContent('buttons.clear_signature')}</TextButton>
                        <PrimaryButton className="ml-6" onClick={handleSignatureConfirm}>{getContent('buttons.accept_signature')}</PrimaryButton>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default AssistanceAddSignature;