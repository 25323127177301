import React from "react";
import { getContent } from "../content";

function Footer() {
  return (
    <footer className="bg-pvr-darkblue">
      <div className="container mx-auto p-4 sm:px-8 md:px-4 md:py-8">
        <p className="text-xs text-center md:text-right text-white">{getContent('general.powered_by')} <a className="underline text-white" href="https://www.seebot.run/" target="_blank">SeeBotRun</a> <a className="underline text-white" href="/privacy-policy">{getContent('general.privacy_policy')}</a> <a className="underline text-white" href="/terms-conditions">Terms & Conditions</a></p>
      </div>
    </footer> 
  );
}

export default Footer;