import React from "react";
import { useNavigate } from "react-router-dom";
import PartyAffiliationForm from "../Forms/PartyAffiliationForm";
import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import BackButton from "../Components/BackButton";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { getContent } from "../content";

function PartyAffiliation() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const registrant = useSelector((state) => state.registrant);

    React.useEffect(() => {
        dispatch(changeStep([4, 6]));
    }, []);

    const onSubmit = (data) => {
        dispatch(updateRegistrant({
            party_affiliation: data.party_affiliation,
            party_affiliation_other: data.party_affiliation == 'OTH' ? data.party_affiliation_other : ''
        }));

        if (registrant.change_name) {
            navigate('/previous-name');
        }
        else if (registrant.change_address) {
            navigate('/previous-address');
        }
        else {
            navigate('/has-mailing-address');
        }
    };

    return (
        <section className="lg:grid grid-cols-12 gap-4">
            <div className="col-start-1 col-span-4">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:mt-8 lg:my-16">{getContent('party_affiliation.heading')}</h2>
            </div>
            <div className="col-start-6 col-span-7">
                <div className="border-4 border-pvr-darkblue rounded-lg p-8">
                    <PartyAffiliationForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default PartyAffiliation;