import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import SecondaryButton from "../Components/SecondaryButton";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import BackButton from "../Components/BackButton";
import { getContent } from "../content";

function EligibilityCitizenship() {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeStep([2, 6]));
    }, []);

    const nextStep = (isCitizen) => {
        if (!isCitizen) {
            dispatch(updateRegistrant({
                check_is_citizen: false,
            }));

            navigate("/not-eligible");
        } else {
            dispatch(updateRegistrant({
                check_is_citizen: true,
            }));

            navigate("/eligibility-age");
        }
    };

    return (
        <>
            <BackButton onClick={() => navigate(-1)} />
            <section className="flex flex-col justify-center h-full">
                <div className="max-w-xl mx-auto">
                    <h2 className="large-text text-center">{getContent('eligibility_citizenship.us_citizen_question')}</h2>
                </div>
                <div className="w-full text-center my-6 md:flex md:flex-row-reverse md:justify-center">
                    <PrimaryButton className="my-4 mx-auto w-full max-w-xs md:m-4" onClick={() => nextStep(true)} large>{getContent('buttons.yes')}</PrimaryButton>
                    <SecondaryButton className="my-4 mx-auto w-full max-w-xs md:m-4" onClick={() => nextStep(false)} large>{getContent('buttons.no')}</SecondaryButton>
                </div>
            </section>
        </>
    );
}

export default EligibilityCitizenship;