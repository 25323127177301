import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { clearCurrentRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { getContent } from "../content";

function PageNotFound() {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeStep([0, 6]));
    }, []);

    const nextStep = () => {
        dispatch(clearCurrentRegistrant());
        navigate("/");
    };

    return (
        <section className="flex flex-col justify-center h-full">
            <div className="w-full text-center">
                <h1>{getContent('page_not_found.heading')}</h1>
            </div>
        </section>
    );
}

export default PageNotFound;