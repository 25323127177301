import React from "react";
import carrot from '../img/carrot.svg';
import { getContent } from "../content";

function BackButton({ onClick, className }) {
  const styleClasses = [
    'font-GilroyExtraBold',
    'text-pvr-midblue',
    'uppercase',
    'text-sm',
    'leading-none',
    'tracking-wider',
    'items-center',
    'inline-flex',
    'lg:text-base'
  ];

  return (
    <button className={`${styleClasses.join(' ')} ${className}`} onClick={onClick} type="button">
      <img className="mr-2" src={carrot} alt="Back" />
      {getContent('buttons.back')}
    </button>
  );
}

export default BackButton;