import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";
import { isValidRegex } from '../helpers';

function SurveyQuestionForm({ question_id, submit }) {
  const registrant = useSelector((state) => state.registrant);
  const partner = useSelector((state) => state.partner);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const [questionText, setQuestionText] = React.useState('');
  const [questionType, setQuestionType] = React.useState('single');
  const [questionPlaceholder, setQuestionPlaceholder] = React.useState('');
  const [questionCharacterLimit, setQuestionCharacterLimit] = React.useState(255);
  const [questionRegex, setQuestionRegex] = React.useState(null);
  const [isRequired, setIsRequired] = React.useState(false);
  const [answerOne, setAnswerOne] = React.useState('');
  const [answerTwo, setAnswerTwo] = React.useState('');
  const [answerThree, setAnswerThree] = React.useState('');

  const { handleSubmit, control, formState: { errors } , setValue, getValues, watch } = useForm({
    defaultValues: {
      survey_question: '',
      answer_01: false,
      answer_02: false,
      answer_03: false,
    }
  });

  const { survey_question, answer_01, answer_02, answer_03 } = watch();

  React.useEffect(() => {
    let question;
    const {
      survey_question_one_answer,
      survey_question_two_answer,
      survey_question_three_answer,
    } = registrant;

    if (question_id === 'question_01') {
      question = partner['survey_question_01'];
    } else if (question_id === 'question_02') {
      question = partner['survey_question_02'];
    } else if (question_id === 'question_03') {
      question = partner['survey_question_03'];
    }

    setQuestionText(question['question_'+currentLanguage] || question['question_en']);
    setQuestionType(question['type']);
    setIsRequired(question['is_required'] || false);
    setQuestionPlaceholder(question['placeholder_'+currentLanguage] || question['placeholder_en'] || '');
    setQuestionCharacterLimit(question['character_limit'] || 255);
    setQuestionRegex(question['regex'] || null);
    setAnswerOne(question['answer_01_'+currentLanguage] || question['answer_01_en'] || null);
    setAnswerTwo(question['answer_02_'+currentLanguage] || question['answer_02_en'] || null);
    setAnswerThree(question['answer_03_'+currentLanguage] || question['answer_03_en'] || null);

    if (question_id === 'question_01') {
      if (partner['survey_'+question_id]['type'] === 'multiple') {
        survey_question_one_answer.split('|').forEach((element) => {
          if (element === question['answer_01_'+currentLanguage]) {
            setValue('answer_01', true);
          } else if (element === question['answer_02_'+currentLanguage]) {
            setValue('answer_02', true);
          } else if (element === question['answer_03_'+currentLanguage]) {
            setValue('answer_03', true);
          }
        });
      } else {
        setValue('survey_question', survey_question_one_answer);
      }
    } else if (question_id === 'question_02') {
      if (partner['survey_'+question_id]['type'] === 'multiple') {
        survey_question_two_answer.split('|').forEach((element) => {
          if (element === question['answer_01_'+currentLanguage]) {
            setValue('answer_01', true);
          } else if (element === question['answer_02_'+currentLanguage]) {
            setValue('answer_02', true);
          } else if (element === question['answer_03_'+currentLanguage]) {
            setValue('answer_03', true);
          }
        });
      } else {
        setValue('survey_question', survey_question_two_answer);
      }
    } else if (question_id === 'question_03') {
      if (partner['survey_'+question_id]['type'] === 'multiple') {
        survey_question_three_answer.split('|').forEach((element) => {
          if (element === question['answer_01_'+currentLanguage]) {
            setValue('answer_01', true);
          } else if (element === question['answer_02_'+currentLanguage]) {
            setValue('answer_02', true);
          } else if (element === question['answer_03_'+currentLanguage]) {
            setValue('answer_03', true);
          }
        });
      } else {
        setValue('survey_question', survey_question_three_answer);
      }
    }
  }, [registrant, question_id, currentLanguage]);


  const onSubmit = (data) => {
    let answer = data.survey_question;

    if (questionType === 'multiple') {
      let answerValues = [];

      if (data.answer_01) {
        answerValues.push(answerOne);
      }

      if (data.answer_02) {
        answerValues.push(answerTwo);
      }

      if (data.answer_03) {
        answerValues.push(answerThree);
      }

      answer = answerValues.join('|');
    }

    if (question_id === 'question_01') {
      submit({
        survey_question_one: questionText,
        survey_question_one_answer: answer,
      });
    } else if (question_id === 'question_02') {
      submit({
        survey_question_two: questionText,
        survey_question_two_answer: answer,
      });
    } else if (question_id === 'question_03') {
      submit({
        survey_question_three: questionText,
        survey_question_three_answer: answer,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>{questionText}</label>

      {questionType === 'single' && (
        <>
          <div className="flex justify-between px-2 py-1 items-center">
            <Controller
              name="survey_question"
              control={control}
              rules={{
                required: isRequired,
              }}
              render={({ field }) => <label className="radio" ><input {...field} type="radio" value={answerOne} defaultChecked={survey_question === answerOne ? true : false} /><span></span> <span className="pl-4">{answerOne}</span></label>}
            />
          </div>
          {answerTwo && (
            <div className="flex justify-between px-2 py-1 items-center ">
              <Controller
                name="survey_question"
                control={control}
                rules={{
                  required: isRequired,
                }}
                render={({ field }) => <label className="radio"><input {...field} type="radio" value={answerTwo} defaultChecked={survey_question === answerTwo ? true : false} /><span></span>  <span className="pl-4">{answerTwo}</span></label>}
              />
            </div>
          )}
          {answerThree && (
            <div className="flex justify-between px-2 py-1 items-center ">
              <Controller
                name="survey_question"
                control={control}
                rules={{
                  required: isRequired,
                }}
                render={({ field }) => <label className="radio"><input {...field} type="radio" value={answerThree} defaultChecked={survey_question === answerThree ? true : false} /><span></span>  <span className="pl-4">{answerThree}</span></label>}
              />
            </div>
          )}
          <div className="flex justify-between items-center ">
            {errors.survey_question?.type === 'required' && <span className="error">{getContent('validation.select_option')}</span>}
          </div>
        </>
      )}

      {questionType === 'input' && (
        <>
          <Controller
            name="survey_question"
            control={control}
            rules={{
              validate: {
                custom: value => !value || (value && isValidRegex(value, questionRegex) || getContent('validation.valid_value'))
              }
            }}
            render={({ field }) => {
              return (
                <input {...field} placeholder={questionPlaceholder} type="text" autoComplete="off" maxLength={questionCharacterLimit} className={`${errors.survey_question ? 'error' : ''}`} />
              );
            }}
          />
          {errors.survey_question?.type === 'custom' && <span className="error">{getContent('validation.valid_value')}</span>}
        </>
      )}

      {questionType === 'multiple' && (
        <>
          {answer_01 !== null && (
            <div className="flex justify-between p-2 items-center ">
              <Controller
                name="answer_01"
                control={control}
                render={({ field }) => <label className="checkbox">{answer_01}<input {...field} type="checkbox" defaultChecked={answer_01} /><span></span> <span>{answerOne}</span></label>}
              />
            </div>
          )}

          {answer_02 !== null && (
            <div className="flex justify-between p-2 items-center ">
              <Controller
                name="answer_02"
                control={control}
                render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={answer_02} /><span></span> <span>{answerTwo}</span></label>}
              />
            </div>
          )}

          {answer_03 !== null && (
            <div className="flex justify-between p-2 items-center ">
              <Controller
                name="answer_03"
                control={control}
                render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={answer_03} /><span></span> <span>{answerThree}</span></label>}
              />
            </div>
          )}
          <div className="flex justify-between items-center ">
            {errors.survey_question?.type === 'required' && <span className="error">{getContent('validation.select_option')}</span>}
          </div>
        </>
      )}

      {questionType === 'multiline' && (
        <>
          <Controller
            name="survey_question"
            control={control}
            rules={{
              validate: {
                custom: value => !value || (value && isValidRegex(value, questionRegex) || getContent('validation.valid_value'))
              }
            }}
            render={({ field }) => {
              return (
                <textarea {...field} rows="8" placeholder={questionPlaceholder} autoComplete="off" maxLength={questionCharacterLimit} className={`${errors.survey_question ? 'error' : ''}`}></textarea>
              );
            }}
          />
          {errors.survey_question?.type === 'custom' && <span className="error">{getContent('validation.valid_value')}</span>}
        </>
      )}

      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default SurveyQuestionForm;
