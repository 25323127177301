import { createSlice } from '@reduxjs/toolkit';

export const informationSlice = createSlice({
    name: 'information',
    initialState: {
        assistanceTypes: {},
        genders: {},
        politicalParties: {},
        races: {},
        suffixes: {},
        unitTypes: {},
        assistanceDeclaration: {},
        registrantDeclaration: {},
        mailinDeclaration: {},
        states: {},
        dates: {},
        counties: {},
    },
    reducers: {
        updateInformation: (state, action) => {
            return {
                ...state,
                assistanceTypes: action.payload.assistance_type,
                genders: action.payload.gender,
                politicalParties: action.payload.political_party,
                races: action.payload.race,
                suffixes: action.payload.suffix,
                unitTypes: action.payload.unit_type,
                assistanceDeclaration: action.payload.assistance_declaration,
                registrantDeclaration: action.payload.registrant_declaration,
                mailinDeclaration: action.payload.mailin_declaration,
                states: action.payload.states,
                dates: action.payload.dates,
                counties: action.payload.counties,
            };
        },
    },
});

export const {
    updateInformation,
} = informationSlice.actions;

export default informationSlice.reducer;
