import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function AdditionalQuestionsForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);

  const { handleSubmit, control, watch, formState: { errors } , setValue, getValues } = useForm({
    defaultValues: {
      is_interested_poll_worker: '',
      is_interested_interpreter: '',
      interpreter_language: ''
    }
  });

  const { is_interested_interpreter } = watch();

  React.useEffect(() => {
    const {
      is_interested_poll_worker,
      is_interested_interpreter,
      interpreter_language
    } = registrant;

    setValue('is_interested_poll_worker', is_interested_poll_worker);
    setValue('is_interested_interpreter', is_interested_interpreter);
    setValue('interpreter_language', interpreter_language);
  }, [registrant]);

  React.useEffect(() => {
    if (is_interested_interpreter == 'no') {
      setValue('interpreter_language', '');
    }
  }, [is_interested_interpreter]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>{getContent("fields.is_interested_poll_worker")}*</label>

      <div className="flex justify-between px-2 py-1 items-center">
        <Controller
          name="is_interested_poll_worker"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => <label className="radio" ><input {...field} type="radio" value="yes" defaultChecked={registrant.is_interested_poll_worker == 'yes' ? true : false} /><span></span> <span className="pl-4">{getContent('buttons.yes')}</span></label>}
        />
      </div>
      <div className="flex justify-between px-2 py-1 items-center ">
        <Controller
          name="is_interested_poll_worker"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="no" defaultChecked={registrant.is_interested_poll_worker == 'no' ? true : false} /><span></span>  <span className="pl-4">{getContent('buttons.no')}</span></label>}
        />
      </div>
      <div className="flex justify-between items-center ">
        {errors.is_interested_poll_worker?.type === 'required' && <span className="error">{getContent('validation.select_option')}</span>}
      </div>


      <label className="mt-4">{getContent('fields.is_interested_interpreter')}*</label>
      <div className="flex justify-between px-2 py-1 items-center ">
        <Controller
          name="is_interested_interpreter"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="yes" defaultChecked={registrant.is_interested_interpreter == 'yes' ? true : false} /><span></span> <span className="pl-4">{getContent('buttons.yes')}</span></label>}
        />
      </div>
      <div className="flex justify-between px-2 py-1 items-center ">
        <Controller
          name="is_interested_interpreter"
          control={control}
          rules={{
            required: true
          }}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="no"   defaultChecked={registrant.is_interested_interpreter == 'no' ? true : false}  /><span></span>  <span className="pl-4">{getContent('buttons.no')}</span></label>}
        />
      </div>

      <div className="flex justify-between items-center ">
        {errors.is_interested_interpreter?.type === 'required' && <span className="error">{getContent('validation.select_option')}</span>}
      </div>
      <div className="flex-1 p-2">
        <label>{getContent('fields.interpreter_language')}</label>
        <Controller
          name="interpreter_language"
          control={control}
          rules={{ 
            required: is_interested_interpreter == 'yes' ? true : false
          }}
          render={({ field }) => <input {...field} type="text" maxLength="50" autoComplete="off" className={`${errors.interpreter_language ? 'error' : ''}`} />}
        />
      </div>

      <div className="flex justify-between items-center ">
        {errors.interpreter_language?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
      </div>

      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default AdditionalQuestionsForm;
