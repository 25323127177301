import React from "react";
import { useNavigate } from "react-router-dom";
import RequiredHelpForm from "../Forms/RequiredHelpForm";
import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import BackButton from "../Components/BackButton";
import { getContent } from "../content";

function RequiredHelp() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const registrant = useSelector((state) => state.registrant);
    const partner = useSelector((state) => state.partner);

    React.useEffect(() => {
        dispatch(changeStep([4, 6]));
    }, []);

    const onSubmit = (data) => {
        if (data.help_required === 'no') {
            data.assistance_type = '';
            data.assistance_language = '';
        }

        dispatch(updateRegistrant({
            help_required: data.help_required,
            assistance_type: data.assistance_type,
            assistance_language: data.assistance_language
        }));
        
        if (partner['survey_question_01']['enabled']) {
            navigate('/survey-question', { state: { question: 'question_01' } });
        } else if (partner['survey_question_02']['enabled']) {
            navigate('/survey-question', { state: { question: 'question_02' } });
        } else if (partner['survey_question_03']['enabled']) {
            navigate('/survey-question', { state: { question: 'question_03' } });
        } else {
            navigate('/review-information');
        }
    };

    return (
        <section className="lg:grid grid-cols-12 gap-4">
            <div className="col-start-1 col-span-4">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:mt-8 lg:mt-16">{getContent('required_help.heading')}</h2>
                <p>{getContent('required_help.content')}</p>
            </div>
            <div className="col-start-6 col-span-7">
                <div className="border-4 border-pvr-darkblue rounded-lg p-8">
                    <RequiredHelpForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default RequiredHelp;