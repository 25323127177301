import React from "react";
import { Link } from "react-router-dom";

function TextButton({ children, onClick, className, href, large, submit }) {
  const styleClasses = [
    'font-GilroyExtraBold',
    'text-white',
    'bg-pvr-darkblue',
    'uppercase',
    'text-sm',
    'md:text-base',
    'py-3',
    'px-6',
    'rounded-full',
    'tracking-wider',
    'leading-none',
    'text-center',
    'inline-block',
  ];

  const largeStyleClasses = [
    'xl:text-lg',
    'xl:py-4',
    'xl:px-8',
    'xl:max-w-sm',
  ];

  return (
    <>
      {href &&
        <Link className={`${styleClasses.join(' ')} ${large ? largeStyleClasses.join(' ') : ''} ${className}`} to={href}>
          {children}
        </Link>
      }
      {!href &&
        <button className={`${styleClasses.join(' ')} ${className}`} onClick={onClick} type={submit ? 'submit' : 'button'}>
          {children}
        </button>
      }
    </>
  );
}

export default TextButton;