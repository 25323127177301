import React from "react";
import { Helmet } from "react-helmet";

const GooglePixel = () => {
  return (
    process.env.REACT_APP_GTM_ENABLED && process.env.REACT_APP_GTM_ENABLED === 'true' ? ( 
        <Helmet>
          <script async src={'https://www.googletagmanager.com/gtag/js?id='+process.env.REACT_APP_GTM_ID}></script>
          <script>
            {
              `window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', '${process.env.REACT_APP_GTM_ID}');
              window.gtag = gtag;`
            }
          </script>
        </Helmet>
    ) : <></>
  );
};

export default GooglePixel;