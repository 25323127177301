import { Provider } from "react-redux";
import { store } from "./store";
import { updateInformation } from "./store/information/informationSlice";
import { updatePartner } from "./store/partner/partnerSlice";
import { getPartnerInfo, getInformation } from "./services/api/partner";
import MetaPixel from "./Utils/MetaPixel";
import GooglePixel from "./Utils/GooglePixel";
import { useEffect } from "react";
import AppStack from "./AppStack";

function App() {
  useEffect(() => {  
    getPartnerInfo().then((result) => {
      let params = new URLSearchParams(window.location.search);

      store.dispatch(updatePartner({
        name: result.partner.name,
        logo_image: result.partner.ui.logo_image,
        logo_image_dark: result.partner.ui.logo_image_dark,
        can_register: result.partner.settings.can_register,
        can_register_online: result.partner.settings.can_register_online,
        can_register_offline: result.partner.settings.can_register_offline,
        no_register_online_message: result.partner.settings.no_register_online_message,
        no_register_offline_message: result.partner.settings.no_register_offline_message,
        no_register_message: result.partner.settings.no_register_message,
        no_register_online_message_es: result.partner.settings.no_register_online_message_es,
        no_register_offline_message_es: result.partner.settings.no_register_offline_message_es,
        no_register_message_es: result.partner.settings.no_register_message_es,
        phone_optin_en: result.partner.settings.phone_optin_en,
        phone_optin_es: result.partner.settings.phone_optin_es,
        phone_optin_zh: result.partner.settings.phone_optin_zh,
        phone_optin_vi: result.partner.settings.phone_optin_vi,
        email_optin_en: result.partner.settings.email_optin_en,
        email_optin_es: result.partner.settings.email_optin_es,
        email_optin_zh: result.partner.settings.email_optin_zh,
        email_optin_vi: result.partner.settings.email_optin_vi,
        enable_mailin_ballot: result.partner.settings.enable_mailin_ballot,
        survey_question_01: result.partner.survey_questions?.question_01,
        survey_question_02: result.partner.survey_questions?.question_02,
        survey_question_03: result.partner.survey_questions?.question_03,
        status: result.status,
        ...(params.has('id') ? {urlID: params.get('id')} : {}),
        ...(params.has('sid') ? {suburlID: params.get('sid')} : {}),
      }));

      store.dispatch(updateInformation(result));
    })
    .catch((error) => {
      //alert(error);
      store.dispatch(updatePartner({
        status: 'error',
      }));
    });
  }, [])

  return (
    <Provider store={store}>
      <MetaPixel />
      <GooglePixel />
      <AppStack />
    </Provider>
  );
}

export default App;
