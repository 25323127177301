import React from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../Components/BackButton";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import LastFourEntryForm from "../Forms/LastFourEntryForm";
import { getContent } from "../content";

function LastFourEntry() {
    const registrant = useSelector((state) => state.registrant);
    let navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeStep([3, 6]));
    }, []);

    const onSubmit = (data) => {
        dispatch(updateRegistrant({
            ...data,
        }));
        
        if (registrant?.id_type == 'have_ssn') {
            navigate("/address-information");
        } else {
            navigate('/party-affiliation');
        }
    };

    return (
        <section className="grid-cols-12 gap-4 lg:grid">
            <div className="col-span-4 col-start-1">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:my-8 lg:my-16">{registrant?.id_type !== 'have_ssn' ? getContent('last_four_entry.with_id_heading') : getContent('last_four_entry.heading')}</h2>
            </div>
            <div className="col-span-7 col-start-6">
                <div className="p-8 border-4 rounded-lg border-pvr-darkblue">
                    <LastFourEntryForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default LastFourEntry;