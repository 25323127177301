import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { updateTracking } from "../store/tracking/trackingSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { getContent } from "../content";

function Home() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const partner = useSelector((state) => state.partner);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    dispatch(changeStep([1, 6]));
  }, []);

  const nextStep = (registered) => {
    const source = searchParams.get('source') ?? null;
    const value = searchParams.get('tracking') ?? null;
    const referrer = document.referrer;

    dispatch(updateTracking({
      ...(source !== null ? {source: source} : {}),
      ...(value !== null ? {value: value} : {}),
      ...(referrer !== null ? {referrer: referrer} : {}),
    }));

    if (!registered) {
      dispatch(updateRegistrant({
        registered: false,
      }));

      navigate("/new-registration");
    } else {
      dispatch(updateRegistrant({
        registered: true,
      }));

      navigate("/update-registration");
    }
  };


  return (
    <section className="flex flex-col justify-center h-full">
      {partner.status !== null ? 
        partner.can_register === true ?
          partner.status === 'error' ? 
            <div className="w-full text-center">
              <h2 className="max-w-xl mx-auto my-4">An error has occurred. Please check the link provided or <a href="https://pavoteforchange.freshdesk.com/support/tickets/new">contact support</a>.</h2>
            </div>
          :
            <div className="w-full text-center">
              <h2 className="max-w-xl mx-auto my-4">{getContent('home.heading')}</h2>
              <h2 className="max-w-xl mx-auto my-4">{getContent('home.content')}</h2>
              <PrimaryButton className="w-full max-w-xs mx-auto my-4 md:m-4" onClick={() => nextStep(false)} large>{getContent('buttons.new_registrant')}</PrimaryButton>
              <PrimaryButton className="w-full max-w-xs mx-auto my-4 md:m-4" onClick={() => nextStep(true)} large>{getContent('buttons.update_registration')}</PrimaryButton>
            </div>
          :
          <div className="w-full text-center">
            <h2 className="max-w-xl mx-auto my-4">Registration is not currently available.</h2>
          </div>
        :
        <></>
      }
    </section>
  );
}

export default Home;
