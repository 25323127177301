import React from "react";
import { useSelector } from 'react-redux';

function ProgressBar() {
  const currentStep = useSelector((state) => state.settings.currentStep);

  return (
    <div className="flex p-0.5 sm:p-1 pt-0.5">
      {[...Array(currentStep[0])].map((x, i) =>
        <span key={`${i}-active`} className="flex-1 h-1.5 sm:h-2.5 inline-block bg-pvr-midblue m-0.5 sm:m-1 rounded-md"></span>
      )}
      {[...Array(currentStep[1] - currentStep[0])].map((x, i) =>
        <span key={`${i}-inactive`} className="flex-1 h-1.5 sm:h-2.5 inline-block bg-pvr-gray m-0.5 sm:m-1 rounded-md"></span>
      )}
    </div>
  );
}

export default ProgressBar;