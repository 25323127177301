import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function IDVerificationForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);

  const { handleSubmit, control, formState: { errors }, setValue } = useForm();

  React.useEffect(() => {
    const {
      id_type,
    } = registrant;

    setValue('id_type', id_type);
  }, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center justify-between p-2 ">
        <Controller
          name="id_type"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="on_person" defaultChecked={registrant.id_type == 'on_person' ? true : false} /><span></span> <span className="pl-4">{getContent('id_verification.license_ask_have_id')}</span></label>}
        />
      </div>
      {/*<div className="flex items-center justify-between p-2 ">
        <Controller
          name="id_type"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="not_on_person" defaultChecked={registrant.id_type == 'not_on_person' ? true : false} /><span></span>  <span className="pl-4">{getContent('id_verification.license_ask_no_id')}</span></label>}
        />
      </div>*/}

      <div className="flex items-center justify-between p-2 ">
        <Controller
          name="id_type"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="have_ssn" defaultChecked={registrant.id_type == 'have_ssn' ? true : false} /><span></span>  <span className="pl-4">{getContent('id_verification.license_ask_have_ssn')}</span></label>}
        />
      </div>

      <div className="flex items-center justify-between p-2 ">
        <Controller
          name="id_type"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="no_id_ssn" defaultChecked={registrant.id_type == 'no_id_ssn' ? true : false} /><span></span> <span className="pl-4">{getContent('id_verification.license_ask_no_id_ssn')}</span></label>}
        />
      </div>

      <div className="flex items-center justify-between ">
        {errors.id_type?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
      </div>

      <div className="flex items-center justify-between ">
        {getContent('id_verification.hava_disclaimer')}
      </div>

      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default IDVerificationForm;