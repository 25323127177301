import React from "react";
import desktopLogo from "../img/PA-VoteforChange_Horizontal.png";
import mobileLogo from "../img/PA-VoteforChange_Primary.png";

function HeaderLogo() {
  return (
    <h1 className="leading-none">
      <a href="/">
        <img className="max-w-xs lg:max-w-sm hidden md:block" src={desktopLogo} alt="PA Vote for Change" />
        <img className="w-16 sm:w-20 md:hidden" src={mobileLogo} alt="PA Vote for Change" />
      </a>
    </h1>
  );
}

export default HeaderLogo;