import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { isValidAge, isValidDate, isValidZip } from '../helpers';
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function NewAddressForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);
  const [municipalities, setMunicipalities] = React.useState([]);

  const { handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      address_1: '',
      address_2: '',
      address_2_type: '',
      address_2_number: '',
      city: '',
      zipcode: '',
      county: '',
      municipality: '',
    }
  });

  React.useEffect(() => {
    const {
      address_1,
      address_2,
      address_2_type,
      address_2_number,
      city,
      zipcode,
      county,
      municipality,
    } = registrant;

    setValue('address_1', address_1);
    setValue('address_2', address_2);
    setValue('address_2_type', address_2_type);
    setValue('address_2_number', address_2_number);
    setValue('city', city);
    setValue('zipcode', zipcode);
    setValue('county', county);
    setValue('municipality', municipality);

    if (information.counties[county]) {
      setMunicipalities(information.counties[county].municipalities || []);
    }
  }, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-1 p-2">
        <label>{getContent('fields.street_address')}*</label>
        <Controller
          name="address_1"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="40" className={`${errors.address_1 ? 'error' : ''}`} />}
        />
        {errors.address_1?.type === 'required' && <span className="error">{getContent('validation.no_address')}</span>}
      </div>

      <div className="flex-1 p-2">
        <label>{getContent('fields.street_address_2')}</label>
        <Controller
          name="address_2"
          control={control}
          render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="40" />}
        />
      </div>

      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.unit_type')}</label>
          <Controller
            name="address_2_type"
            control={control}
            render={({ field }) => {
              return (
                <div className="select">
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                    {Object.keys(information?.unitTypes).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.unitTypes[key][currentLanguage]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.unit_number')}</label>
          <Controller
            name="address_2_number"
            control={control}
            render={({ field }) => <input {...field} placeholder="40B" type="text" autoComplete="off" maxLength="15" />}
          />
        </div>
      </div>

      <div className="flex">

        <div className="flex-1 p-2">
          <label>{getContent('fields.city')}*</label>
          <Controller
            name="city"
            control={control}
            rules={{ required: true }}

            render={({ field }) => <input {...field} placeholder="City" type="text" autoComplete="off" maxLength="35" className={`${errors.city ? 'error' : ''}`} />}
          />
          {errors.city?.type === 'required' && <span className="error">{getContent('validation.city')}</span>}

        </div>

        <div className="flex-1 p-2">
          <label>{getContent('fields.zipcode')}*</label>
          <Controller
            name="zipcode"
            control={control}
            rules={{
              required: true,
              maxlength: 5,
              validate: {
                zipcode: value => isValidZip(value) || getContent('validation.no_address_zipcode')
              }
            }}
            render={({ field }) => {
              return (
                <InputMask placeholder="12345" className={`input ${errors.zipcode ? 'error' : ''}`} {...field} mask="99999" />
              )
            }}
          />
          {errors.zipcode?.type === 'required' && <span className="error">{getContent('validation.no_address_zipcode')}</span>}
          {errors.zipcode?.type === 'zipcode' && <span className="error">{getContent('validation.no_address_zipcode_valid')}</span>}
        </div>
      </div>


      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.county')}*</label>
          <Controller
            name="county"
            control={control}
            rules={{ required: true, }}
            render={({ field }) => {
              return (
                <div className={`select ${errors.county ? 'error' : ''}`} >
                  <select {...field} onChange={(e) => { field.onChange(e.target.value); setMunicipalities(information.counties[e.target.value].municipalities || []); }}>
                    {Object.keys(information?.counties).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.counties[key].name}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
          {errors.county?.type === 'required' && <span className="error">{getContent('validation.county')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.municipality')}</label>
          <Controller
            name="municipality"
            control={control}
            render={({ field }) => {
              return (
                <div className={`select ${errors.municipality ? 'error' : ''}`} >
                  <select {...field}>
                    {Object.keys(municipalities).map((key, i) => {
                      return (
                        <option key={i} value={key}>{municipalities[key]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default NewAddressForm;