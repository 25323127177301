import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: {
        currentLanguage: 'en',
        currentStep: [1, 6]
    },
    reducers: {
        changeLanguage: (state, action) => {
            window.CURRENT_LANGUAGE = action.payload;
            return {
                ...state,
                currentLanguage: action.payload
            };
        },
        changeStep: (state, action) => {
            return {
                ...state,
                currentStep: action.payload
            };
        },
    },
});

export const {
    changeLanguage,
    changeStep
} = settingsSlice.actions;

export default settingsSlice.reducer;
