import React from "react";
import { useNavigate } from "react-router-dom";
import BallotRequestForm from "../Forms/BallotRequestForm";
import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import BackButton from "../Components/BackButton";
import { getContent } from "../content";

function BallotRequest() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const registrant = useSelector((state) => state.registrant);

    React.useEffect(() => {
        dispatch(changeStep([4, 6]));
    }, []);

    const onSubmit = (data) => {
        dispatch(updateRegistrant({
            ballot_request: data.ballot_request,
            ballot_request_address_type: data.ballot_request_address_type,
            ballot_request_address_1: data.ballot_request_address_1,
            ballot_request_city: data.ballot_request_city,
            ballot_request_state: data.ballot_request_state,
            ballot_request_zipcode: data.ballot_request_zipcode,
            ballot_request_ward: data.ballot_request_ward,
            ballot_request_lived_since: data.ballot_request_lived_since,
        }));

        if (data.ballot_request.toLowerCase() === 'yes') {
            navigate('/ballot-request-confirmation');
        } else {
            navigate('/had-assistance');
        }
    };

    return (
        <section className="lg:grid grid-cols-12 gap-4">
            <div className="col-start-1 col-span-4">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:mt-8 lg:mt-16">{getContent('ballot_request.heading')}</h2>
                <p>{getContent('ballot_request.content')}</p>
                <p>{getContent('ballot_request.content2')}</p>
            </div>
            <div className="col-start-6 col-span-7">
                <div className="border-4 border-pvr-darkblue rounded-lg p-8">
                    <BallotRequestForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default BallotRequest;