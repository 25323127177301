import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import StayInTouchForm from "../Forms/StayInTouchForm";
import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import { generateRegistrationID, createFormPayload, sendSurveyData, getPublicKey } from '../services/api/voter';
import Bugsnag from '@bugsnag/js';
import { getContent } from "../content";

function StayInTouch() {
    const partner = useSelector((state) => state.partner);
    const registrant = useSelector((state) => state.registrant);
    const tracking = useSelector((state) => state.tracking);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);

    let navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = React.useState(false);

    React.useEffect(() => {
        dispatch(changeStep([0, 6]));
    }, []);

    const onSubmit = async (data) => {
        setIsSubmitting(true);

        const responseID = generateRegistrationID();
        const pubKey = await getPublicKey();
        const encryptedResult = createFormPayload(registrant, tracking, location?.state?.registrationType, currentLanguage, pubKey.public_key);

        sendSurveyData(responseID, null, partner.urlID, partner.suburlID, 'form', encryptedResult.key, encryptedResult.payload).then(() => {
            setIsSubmitting(false);
            navigate("/thank-you");
        }).catch(async (error) => {
            alert('An error occurred while saving your information.  Please try again.');
            setIsSubmitting(false);
        });
    };

    return (
        <section className="grid-cols-12 gap-4 lg:grid">
            <div className="col-span-4 col-start-1">
                <h2 className="my-4 md:mt-8 lg:mt-16">{getContent('stay_in_touch.heading')}</h2>
                <p>{getContent('stay_in_touch.content')}</p>
            </div>
            <div className="col-span-7 col-start-6">
                <div className="p-8 border-4 rounded-lg border-pvr-darkblue">
                    <StayInTouchForm submit={onSubmit} disabled={isSubmitting} />
                </div>
            </div>
        </section>
    );
}

export default StayInTouch;