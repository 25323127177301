import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import TextButton from "../Components/TextButton";
import { useForm, Controller } from "react-hook-form";
import { isValidZip } from '../helpers';
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function CurrentAddressForm({ submit, submitText, cancel }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);

  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    defaultValues: {
      federal_state_employee_address_1: '',
      federal_state_employee_city: '',
      federal_state_employee_state: '',
      federal_state_employee_zipcode: '',
    }
  });

  const onSubmit = data => submit(data);

  React.useEffect(() => {
    const {
      federal_state_employee_address_1,
      federal_state_employee_city,
      federal_state_employee_state,
      federal_state_employee_zipcode,
    } = registrant;

    setValue('federal_state_employee_address_1', federal_state_employee_address_1);
    setValue('federal_state_employee_city', federal_state_employee_city);
    setValue('federal_state_employee_state', federal_state_employee_state);
    setValue('federal_state_employee_zipcode', federal_state_employee_zipcode);
  }, [registrant]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className="flex-1 p-2">
        <label>{getContent('fields.street_address')}*</label>
        <Controller
          name="federal_state_employee_address_1"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="40" className={`${errors.federal_state_employee_address_1 ? 'error' : ''}`} />}
        />
        {errors.federal_state_employee_address_1?.type === 'required' && <span className="error">{getContent('validation.street_address')}</span>}
      </div>

      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.city')}*</label>
          <Controller
            name="federal_state_employee_city"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <input {...field} placeholder="City" type="text" autoComplete="off" maxLength="35" className={`${errors.federal_state_employee_city ? 'error' : ''}`} />}
          />
          {errors.federal_state_employee_city?.type === 'required' && <span className="error">{getContent('validation.city')}</span>}

        </div>


        <div className="flex-1 p-2">
          <label>{getContent('fields.state')}*</label>
          <Controller
            name="federal_state_employee_state"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <div className={`select ${errors.federal_state_employee_state ? 'error' : ''}`} >
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                    {Object.keys(information?.states).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.states[key]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
          {errors.federal_state_employee_state?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
        </div>

      </div>

      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.zipcode')}*</label>
          <Controller
            name="federal_state_employee_zipcode"
            control={control}
            rules={{
              required: true,
              maxlength: 10,
              validate: {
                zipcode: value => isValidZip(value) || getContent('validation.zipcode_valid')
              }
            }}
            render={({ field }) => {
              return (
                <InputMask placeholder="12345" className={`input ${errors.federal_state_employee_zipcode ? 'error' : ''}`} {...field} mask="99999" />
              )
            }}
          />
          {errors.federal_state_employee_zipcode?.type === 'required' && <span className="error">{getContent('validation.zipcode')}</span>}
          {errors.federal_state_employee_zipcode?.type === 'zipcode' && <span className="error">{getContent('validation.zipcode_valid')}</span>}
        </div>
      </div>
      <div className="flex justify-end pt-8 pb-2">
        <TextButton onClick={cancel} className="pr-2">{getContent('buttons.cancel')}</TextButton>
        <PrimaryButton submit>{!submitText ? getContent('buttons.next_step') : submitText}</PrimaryButton>
      </div>
    </form>
  );
}

export default CurrentAddressForm;