import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import PrimaryButton from "../Components/PrimaryButton";
import TextButton from "../Components/TextButton";
import SignaturePad from "react-signature-canvas";
import Modal from 'react-modal';
import edit from '../img/edit.svg';
import { useForm, Controller } from "react-hook-form";
import BackButton from "../Components/BackButton";
import { useSelector } from 'react-redux';
import { getContent } from "../content";
import Resizer from "react-image-file-resizer";
import dataURLtoBlob from 'blueimp-canvas-to-blob';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function AddSignature() {
    const registrant = useSelector((state) => state.registrant);
    const information = useSelector((state) => state.information);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);
    const partner = useSelector((state) => state.partner);

    let navigate = useNavigate();
    const [signature, setSignature] = React.useState(null);
    const [signatureHD, setSignatureHD] = React.useState(null);
    const dispatch = useDispatch();
    const [modalIsOpen, setIsOpen] = React.useState(false);
    let sigPad = React.useRef();

    const { handleSubmit, control, formState: { errors }, getValues } = useForm();

    React.useEffect(() => {
        dispatch(changeStep([5, 6]));
    }, []);

    React.useEffect(() => {
        const {
            signature
        } = registrant;

        setSignature(signature);
    }, [registrant]);

    const handleSignatureConfirm = async () => {
        if (!sigPad.isEmpty()) {
            let file = b64toFile(sigPad.getCanvas().toDataURL("image/png"));
            setSignatureHD(sigPad.getCanvas().toDataURL("image/png").replace("data:image/png;base64,", ""));

            let resized = await getResizedImage(file);

            setSignature(
                resized.replace("data:image/png;base64,", "")
            );

            closeModal();
        }
    };

    const getResizedImage = (file) => {
        return new Promise(( resolve ) => {
            Resizer.imageFileResizer(
                file,
                180,
                60,
                'PNG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'base64'
            );
        });
    };

    const b64toByteArrays = (b64Data, contentType) => {
        contentType = contentType || "image/png";
        var sliceSize = 512;
    
        var byteCharacters = atob(
          b64Data.toString().replace(/^data:image\/(png|jpeg|jpg|webp);base64,/, "")
        );
        var byteArrays = [];
    
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          var slice = byteCharacters.slice(offset, offset + sliceSize);
    
          var byteNumbers = new Array(slice.length);
          for (var i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
    
          var byteArray = new Uint8Array(byteNumbers);
    
          byteArrays.push(byteArray);
        }
        return byteArrays;
      }
    
      const b64toBlob = (b64Data, contentType) => {
        const byteArrays = b64toByteArrays(b64Data, contentType);
        var blob = new Blob(byteArrays, { type: contentType, lastModified: new Date() });
        return blob;
      }
    
      const b64toFile = (b64Data, fileName, contentType) => {
        const byteArrays = b64toByteArrays(b64Data, contentType);
        const file = new File(byteArrays, fileName, { type: contentType, lastModified: new Date() });
        return file;
      }

    const clearSignature = () => {
        sigPad.clear();
    };

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
    }

    function closeModal() {
        setIsOpen(false);
    }

    const addSignature = () => {
        openModal();
    };

    const nextStep = () => {
        dispatch(updateRegistrant({
            confirm_terms: true,
            has_signature: true,
            signature_data: signature,
            signature_data_hd: signatureHD
        }));

        const {
            id_type,
        } = registrant;

        if (id_type === 'on_person' && partner['enable_mailin_ballot']) {
            navigate('/ballot-request');
        } else {
            navigate('/had-assistance');
        }
    };

    return (
        <>
            <section className="lg:grid grid-cols-12 gap-4">
                <div className="col-start-1 col-span-4">
                    <BackButton onClick={() => navigate(-1)} />
                    <h2 className="my-4 md:mt-8 lg:mb-16">{getContent('add_signature.heading')}</h2>
                </div>
                <div className="col-start-6 col-span-7">
                    <div className="border-4 border-pvr-darkblue rounded-lg p-8" dangerouslySetInnerHTML={{ __html: information.registrantDeclaration[currentLanguage] }}>

                    </div>

                    {signature &&
                        <>
                            <div>
                                <h4 className="text-center mt-6 uppercase text-pvr-orange">
                                    {getContent('add_signature.review_your_signature')}
                                </h4>
                                <div className="mt-6 w-full flex flex-col justify-center items-center h-34 rounded-lg border-4 border-pvr-darkblue overflow-hidden mx-auto relative">
                                    <button type="button" onClick={openModal} className="block absolute top-4 right-4">
                                        <img src={edit} className="w-6 h-6" alt="edit" />
                                    </button>
                                    <img
                                        className="mx-auto block"
                                        src={`data:image/png;base64,${signature}`}
                                        width="360"
                                        height="120"
                                        alt="Signature"
                                    />
                                </div>
                            </div>
                            <div className="flex pt-4 px-2 pb-2">
                                <Controller
                                    name="confirm_terms"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" /><span></span> <span>{getContent('add_signature.review_signature_terms_confirm')}</span></label>}
                                />
                            </div>
                            {errors.confirm_terms?.type === 'required' && <span className="error">{getContent('validation.signature_confirmation_message')}</span>}
                            <div className="flex justify-end pt-4 pb-2">
                                <PrimaryButton onClick={handleSubmit(nextStep)}>{getContent('buttons.next_step')}</PrimaryButton>
                            </div>
                        </>
                    }
                    {!signature &&
                        <div className="flex justify-end pt-8 pb-2">
                            <PrimaryButton onClick={addSignature}>{getContent('buttons.add_signature')}</PrimaryButton>
                        </div>
                    }
                </div>
            </section>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                ariaHideApp={false}
                style={customStyles}
            >
                <div className="text-center mt-6">
                    <h4>{getContent('add_signature.review_signature_sign')}</h4>
                    <div className="mt-6 w-full h-30 rounded-lg border-4 border-pvr-darkblue overflow-hidden mx-auto">
                        <div className="sigContainer">
                            <SignaturePad
                                canvasProps={{ 
                                    className: "sigPad",
                                }}
                                backgroundColor="rgba(255, 255, 255, 1)"
                                ref={(ref) => (sigPad = ref)}
                            />
                        </div>
                    </div>
                    <div className="flex justify-end pt-8 pb-2 items-center">
                        <TextButton onClick={clearSignature}>{getContent('buttons.clear_signature')}</TextButton>
                        <PrimaryButton className="ml-6" onClick={handleSignatureConfirm}>{getContent('buttons.accept_signature')}</PrimaryButton>
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default AddSignature;
