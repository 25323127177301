import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import SecondaryButton from "../Components/SecondaryButton";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import BackButton from "../Components/BackButton";
import { getContent } from "../content";

function EligibilityNotice() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const information = useSelector((state) => state.information);

    React.useEffect(() => {
        dispatch(changeStep([2, 6]));
    }, []);

    const nextStep = (isEligible) => {
        if (!isEligible) {
            navigate("/not-eligible");
        } else {
            navigate("/eligibility-citizenship");
        }
    };

    return (
        <>
            <BackButton onClick={() => navigate(-1)} />
            <section className="flex flex-col justify-center h-full">
                <div className="max-w-xl mx-auto">
                    <h2 className="text-center large-text">{getContent('eligibility_notice.heading')}</h2>           
                    <ul>
                        <li>{getContent('eligibility_notice.content_1')}</li>
                        <li>{getContent('eligibility_notice.content_2')}</li>
                        <li>{getContent('eligibility_notice.content_3')} ({information?.dates?.next_election || 'n/a'})</li>
                    </ul>,
                </div>
                <div className="w-full my-6 text-center md:flex md:flex-row-reverse md:justify-center">
                    <PrimaryButton className="w-full max-w-xs mx-auto my-4 md:m-4" onClick={() => nextStep(true)} large>{getContent('buttons.proceed')}</PrimaryButton>
                    <SecondaryButton className="w-full max-w-xs mx-auto my-4 md:m-4" onClick={() => nextStep(false)} large>{getContent('buttons.cancel')}</SecondaryButton>
                </div>
            </section>
        </>
    );
}

export default EligibilityNotice;