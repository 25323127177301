import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function HadAssistanceForm({ submit, disabled }) {
  const registrant = useSelector((state) => state.registrant);
  const [nextStepText, setNextStepText] = React.useState(getContent('buttons.complete_registration'));

  const { handleSubmit, control, formState: { errors } , setValue } = useForm({
    had_assistance: '',
  });

  React.useEffect(() => {
    const {
      had_assistance,
    } = registrant;

    setValue('had_assistance', had_assistance);
  }, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex items-center justify-between p-2 ">
        <Controller
          name="had_assistance"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="no" defaultChecked={registrant.had_assistance =="no" ? true : false} onChange={(e) => { field.onChange(e.target.value); setNextStepText(getContent('buttons.complete_registration')); }} /><span></span> <span className="pl-4">{getContent('fields.no_assistance')}</span></label>}
        />
      </div>
      <div className="flex items-center justify-between p-2 ">
        <Controller
          name="had_assistance"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="yes" defaultChecked={registrant.had_assistance == "yes" ? true : false} onChange={(e) => { field.onChange(e.target.value); setNextStepText(getContent('buttons.next_step')); }} /><span></span>  <span className="pl-4">{getContent('fields.yes_assistance')}</span></label>}
        />
      </div>

      <div className="flex items-center justify-between ">
        {errors.had_assistance?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
      </div>
      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit disabled={disabled}>{nextStepText}</PrimaryButton>
      </div>
    </form>
  );
}

export default HadAssistanceForm;