import React from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import TertiaryButton from "../Components/TertiaryButton";
import { getContent } from "../content";

function UpdateInformationForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);
  const navigate = useNavigate();
  const [isValid, setIsValid] = React.useState(true);

  const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm({
    defaultValues: {
      change_name: false,
      change_address: false,
      change_party: false,
      federal_state_employee: false,
    }
  });

  React.useEffect(() => {
    const {
      change_name,
      change_address,
      change_party,
      federal_state_employee
    } = registrant

    setValue('change_name', change_name);
    setValue('change_address', change_address);
    setValue('change_party', change_party);
    setValue('federal_state_employee', federal_state_employee);
  }, [registrant]);

  const onSubmit = function (data) {
    if (data.change_name || data.change_address || data.change_party) {
      submit(data);
    } else {
      setIsValid(false);
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
     
      <div className="flex justify-between p-2 items-center ">
        <Controller
          name="change_name"
          control={control}
          render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.change_name} /><span></span> <span>{getContent('update_information.change_name')}</span></label>}
        />
      </div>

      <div className="flex justify-between p-2 items-center ">
        <Controller
          name="change_address"
          control={control}
          render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.change_address} /><span></span> <span>{getContent('update_information.change_address')}</span></label>}
        />
      </div>

      <div className="flex justify-between p-2 items-center ">
        <Controller
          name="change_party"
          control={control}
          render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.change_party} /><span></span> <span>{getContent('update_information.change_party')}</span></label>}
        />
      </div>

      <div className="flex justify-between items-center ">
        {!isValid && <span className="error">{getContent('update_information.error')}</span>}
      </div>

      <div className="flex justify-between p-2 pt-6 items-center ">
        <Controller
          name="federal_state_employee"
          control={control}
          render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.federal_state_employee} /><span></span> <span>{getContent('update_information.federal_employee')}</span></label>}
        />
      </div>
     
      <div className="flex justify-end pt-8 pb-2">
        <TertiaryButton onClick={() => navigate("/stay-in-touch", {state: {registrationType: 'on_roll'}})}>{getContent('buttons.no_changes')}</TertiaryButton>
        <PrimaryButton className="ml-6" submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default UpdateInformationForm;