import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useNavigate } from "react-router-dom";

import { useDispatch } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import splash from '../img/splash.svg';
import { getContent } from "../content";

function NotEligible() {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeStep([0, 6]));
        document.getElementById('content').classList.add('splash-bkgd');
    }, []);

    const nextStep = () => {
        navigate("/stay-in-touch", {state: {registrationType: 'not_eligible'}});
    };

    return (
        <>
            <section className="flex flex-col justify-center h-full text-center">
                <div className="max-w-xl mx-auto">
                    <h2 className="large-text">{getContent('not_eligible.heading')}</h2>
                    <p className="my-4">{getContent('not_eligible.content')}</p>
                </div>
                <img className="max-w-xl mx-auto block" src={splash} alt="" />
                <div className="flex justify-center mt-6">
                    <PrimaryButton className="max-w-xs md:m-4" onClick={() => nextStep()} large>{getContent('buttons.stay_in_touch')}</PrimaryButton>
                </div>
            </section>
        </>
    );
}

export default NotEligible;