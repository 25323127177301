import React from "react";
import Modal from 'react-modal';
import SecondaryButton from "../Components/SecondaryButton";
import PrimaryButton from "../Components/PrimaryButton";
import { useSelector } from 'react-redux';
import ReactCodeInput from 'react-code-input';
import TextButton from "../Components/TextButton";
import { useNavigate } from "react-router-dom";
import { getContent } from "../content";

const props = {
  inputStyle: {
    margin: '4px',
    MozAppearance: 'textfield',
    width: '38px',
    borderRadius: '8px',
    fontSize: '18px',
    height: '45px',
    color: 'black',
    padding: '0px',
    textAlign: 'center',
    border: '2px solid #0D326A',
    fontFamily: 'Gilroy-ExtraBold',
    WebkitAppearance: 'none',
  },
  inputStyleInvalid: {
    margin: '4px',
    MozAppearance: 'textfield',
    width: '36px',
    borderRadius: '8px', fontSize: '18px',

    height: '36px',
    color: 'black',
    textAlign: 'center',
    fontFamily: 'Gilroy-ExtraBold',
    border: '2px solid rgb(239, 68, 68)',
    WebkitAppearance: 'none',

  }
}

function LastFourEntryForm({ submit, submitText, cancel }) {
  const registrant = useSelector((state) => state.registrant);
  const [key, setKey] = React.useState(Math.random());
  const [lastFour, setLostFour] = React.useState('');
  const [showError, setShowError] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);
  let navigate = useNavigate();

  React.useEffect(() => {
    const {
      last_four_ssn,
    } = registrant;

    setKey(Math.random());
    setLostFour(last_four_ssn)
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    if ((lastFour.length > 0 && lastFour.length < 4) || lastFour === '0000') {
      setShowError(true);
    } else if ((lastFour.length < 4) && submitText) {
      setShowError(true);
    } else {
      setShowError(false);
      //submit({ last_four_ssn: lastFour })
      if ((lastFour === null || lastFour === '') && registrant?.id_type !== 'on_person') {
        setShowModal(true);
      } else {
        submit({ 
          last_four_ssn: lastFour 
        })
      }
    }
  };

  const modalSubmit = () => {
    let idType = registrant?.id_type;

    if (idType === 'have_ssn' && lastFour === '') {
      idType = 'no_id_ssn';
    }

    console.log(idType);

    submit({ 
      last_four_ssn: lastFour,
      id_type: idType,
    });
  };

  const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
    },
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <div className="flex items-center justify-center mt-6 mb-4">
          <span className="text-3xl tracking-wider black font-GilroyExtraBold">XXX-XX-</span>
          <ReactCodeInput type='number' fields={4} value={lastFour} key={key} onChange={(value) => {
            if (value.length > 0 && value.length < 4) {
              setShowError(true);
            } else {
              setShowError(false);
            }
            setLostFour(value);
          }} {...props} />
        </div>
        {showError && <span className="text-center error">{getContent('fields.last_four')}</span>}
        <div className="flex justify-end pt-8 pb-2">
          {!submitText && registrant?.id_type !== 'have_ssn' &&
            <TextButton onClick={() => {
              if (registrant?.id_type === 'have_ssn') {
                navigate('/not-eligible');
              } else {
                navigate('/party-affiliation');
              }
            }}>{getContent('buttons.no_ssn')}</TextButton>
          }
          {submitText && (
            <TextButton onClick={cancel} className="pr-2">{getContent('buttons.cancel')}</TextButton>
          )}
          <PrimaryButton submit className="ml-6">{!submitText ? getContent('buttons.next_step') : submitText}</PrimaryButton>
        </div>
      </form>

      <Modal
        isOpen={showModal}
        ariaHideApp={false}
        style={customStyles}
        >
        <div className="mt-6">
            <h4 className="p-2 mb-4 tracking-wide uppercase text-pvr-orange">{getContent('last_four_entry.no_ssn_heading')}</h4>
            <p>{getContent('last_four_entry.no_ssn_body')}</p>
            <div className="w-full my-6 text-center md:flex md:flex-row-reverse md:justify-center">
              <SecondaryButton className="max-w-xs my-4 md:m-4" onClick={() => setShowModal(false)}>{getContent('buttons.cancel')}</SecondaryButton>
              <PrimaryButton submit className="max-w-xs my-4 md:m-4" onClick={modalSubmit}>{getContent('buttons.continue_without_ssn')}</PrimaryButton>
            </div>
        </div>
      </Modal>
    </>
  );
}

export default LastFourEntryForm;