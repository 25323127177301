
export const getPartnerInfo = () => {
    let params = new URLSearchParams(window.location.search);
    return fetch(`${process.env.REACT_APP_API_BASE}partner/info?website_code=${process.env.REACT_APP_PARTNER}`, { 
        method: 'POST', 
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json'}, 
        body: JSON.stringify({
            website_code: process.env.REACT_APP_PARTNER,
            id: params.get('id'),
            sid: params.get('sid'),
        })})
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json()
        })
        .catch((error) => {
            throw error;
        });
}

export const getInformation = () => {
    return fetch(`${process.env.REACT_APP_API_BASE}partner/information?canvasser_id=${process.env.REACT_APP_CANVASSER_ID}`).then((response) => {
        if (!response.ok) {
            throw Error(response.statusText);
        }

        return response.json()
    }).catch((error) => {
        console.log(error);
    });
}