import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    source: null,
    value: null,
    referrer: null,
};

export const trackingSlice = createSlice({
    name: 'tracking',
    initialState: initialState,
    reducers: {
        updateTracking: (state, action) => {
            return {
                ...state,
                ...action.payload
            };
        },
        clearCurrentTracking: () =>  initialState,
    },
});

export const {
    updateTracking,
    clearCurrentTracking,
} = trackingSlice.actions;

export default trackingSlice.reducer;
