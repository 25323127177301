import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { isValidIDNumber } from '../helpers';
import { useSelector } from 'react-redux';
import { getContent } from "../content";
import TextButton from "../Components/TextButton";

function IDForm({ submit, cancel }) {
  const registrant = useSelector((state) => state.registrant);

  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    defaultValues: {
      pa_id_number: '',
    }
  });

  React.useEffect(() => {
    const {
        pa_id_number,
    } = registrant;

    setValue('pa_id_number', pa_id_number);
}, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
     
      <div className="flex-1 p-2">
        <label>{getContent('fields.license_id')}*</label>
        <Controller
          name="pa_id_number"
          control={control}
          rules={{
            required: true,
            validate: {
              license: value => isValidIDNumber(value) || getContent('validation.license_id_valid')
            }
          }}
          render={({ field }) => {
            return (
              <InputMask placeholder="12 345 678" className={`input ${errors.pa_id_number ? 'error' : ''}`} {...field} mask="99 999 999" />
            )
          }}
        />
        {errors.pa_id_number?.type === 'required' && <span className="error">{getContent('validation.id')}</span>}
        {errors.pa_id_number?.type === 'license' && <span className="error">{getContent('validation.license_id_valid')}</span>}
      </div>

      <div className="flex justify-end pt-8 pb-2">
        <TextButton onClick={cancel} className="pr-2">{getContent('buttons.cancel')}</TextButton>
        <PrimaryButton submit>{getContent('buttons.update_information')}</PrimaryButton>
      </div>
    </form>
  );
}

export default IDForm;