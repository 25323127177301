import { configureStore } from '@reduxjs/toolkit';
import securityReducer from './security/securitySlice';
import registrantReducer from './registrant/registrantSlice';
import trackingReducer from './tracking/trackingSlice';
import settingsReducer from './settings/settingsSlice';
import informationReducer from './information/informationSlice';
import partnerReducer from './partner/partnerSlice';
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage/session'

const trackingPersistConfig = {
  timeout: 0,
  key: 'tracking',
  storage: storage,
  whitelist: [
    'source',
    'value',
    'referrer',
  ],
};

const partnerPersistConfig = {
  timeout: 0,
  key: 'partner',
  storage: storage,
  whitelist: [
    'urlID',
    'suburlID',
  ],
  blacklist: [
  ],
};

const rootReducer = combineReducers({
    security: securityReducer,
    registrant: registrantReducer,
    settings: settingsReducer,
    information: informationReducer,
    partner: persistReducer(partnerPersistConfig, partnerReducer),
    tracking: persistReducer(trackingPersistConfig, trackingReducer),
});

const middlewares = [];
 
if (process.env.NODE_ENV === `development`) {
  middlewares.push(createLogger());
}

const store = configureStore({
    reducer: rootReducer,
    middleware: middlewares,
});

const persistor = persistStore(store);

export { store, persistor };
