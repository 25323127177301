import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function BallotRequestConfirmationForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);

  const { handleSubmit, control, formState: { errors } , setValue } = useForm({
    ballot_request_confirmed: false,
  });

  React.useEffect(() => {
    const {
      ballot_request_confirmed,
    } = registrant;

    setValue('ballot_request_confirmed', ballot_request_confirmed);
  }, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex pt-4 px-2 pb-2">
        <Controller
            name="ballot_request_confirmed"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" /><span></span> <span>{getContent('ballot_request.review_terms_confirm')}</span></label>}
        />
      </div>
      <div className="flex justify-between items-center ">
        {errors.ballot_request_confirmed?.type === 'required' && <span className="error">{getContent('validation.signature_confirmation_message')}</span>}
      </div>
      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default BallotRequestConfirmationForm;