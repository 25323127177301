import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearCurrentRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import splash from '../img/splash.svg';
import { getContent } from "../content";

function ThankYou() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    // const currentLanguage = useSelector(state => state.settings.currentLanguage);

    React.useEffect(() => {
        dispatch(changeStep([0, 6]));
        dispatch(clearCurrentRegistrant());
        document.getElementById('content').classList.add('splash-bkgd');
        window.onpopstate = function () {
            window.location.href = '/'; 
        };
    }, []);

    const nextStep = () => {
        navigate("/");
    };

    return (
        <>
            <section className="flex flex-col justify-center h-full text-center">
                <div className="max-w-xl mx-auto mb-4">
                    <h2 className="large-text">{getContent('thank_you.heading')}</h2>
                    <p className="my-4">{getContent('thank_you.content')}</p>
                </div>
                <img className="max-w-xl mx-auto block" src={splash} alt="" />
                <div className="flex justify-center mt-6">
                    <PrimaryButton className="max-w-xs md:m-4" onClick={() => nextStep()} large>{getContent('buttons.start_new_registration')}</PrimaryButton>
                </div>
            </section>
        </>
    );
}

export default ThankYou;

// const content = {
//     ['en-US']: {
//       heading: "Thank You!",
//       content: "We'll keep in touch about other services coming on voting day!",
//       action: "Start New Registration",
//     },
//     ['es-ES']: {
//       heading: "ES - Thank You!",
//       content: "ES - We'll keep in touch about other services coming on voting day!",
//       action: "ES - Start New Registration",
//     }
//   }
