import { createSlice } from '@reduxjs/toolkit';

export const securitySlice = createSlice({
    name: 'security',
    initialState: {
        partner_code: '',
        partner_name: '',
        partner_logo_image: '',
        partner_primary_color: '',
        partner_secondary_color: '',
    },
    reducers: {
        
    },
});

export const { 

} = securitySlice.actions;

export default securitySlice.reducer;
