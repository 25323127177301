import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function RequiredHelpForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const { handleSubmit, control, formState: { errors }, setValue, watch } = useForm({
    defaultValues: {
      help_required: '',
      assistance_type: '',
      assistance_language: ''
    }
  });

  const { help_required, assistance_language, assistance_type } = watch();

  React.useEffect(() => {
    const {
      help_required,
      assistance_type,
      assistance_language
    } = registrant;

    setValue('help_required', help_required);
    setValue('assistance_type', assistance_type);
    setValue('assistance_language', assistance_language);
  }, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <label>{getContent('required_help.heading')}*</label>

      <div className="flex justify-between px-2 py-1 items-center">
        <Controller
          name="help_required"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="yes" defaultChecked={registrant.help_required =='yes' ? true : false} /><span></span> <span className="pl-4">{getContent('buttons.yes')}</span></label>}
        />
      </div>
      <div className="flex justify-between px-2 py-1 items-center ">
        <Controller
          name="help_required"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => <label className="radio"><input {...field} type="radio" value="no" defaultChecked={registrant.help_required =='no' ? true : false} /><span></span>  <span className="pl-4">{getContent('buttons.no')}</span></label>}
        />
      </div>
      <div className="flex justify-between items-center ">
        {errors.help_required?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
      </div>

      {help_required == 'yes' && (
        <>
          <div className="flex-1 p-2">
            <label>{getContent('required_help.label')}</label>
            <Controller
              name="assistance_type"
              control={control}
              rules={{
                required: (help_required == 'yes' && assistance_language == '') ? true : false
              }}
              render={({ field }) => {
                return (
                  <div className={`select ${errors.assistance_type ? 'error' : ''}`} >
                    <select {...field}>
                      <option value="">{getContent('placeholders.dropdown')}</option>
                        {Object.keys(information?.assistanceTypes).map((key, i) => {
                          if (information?.assistanceTypes[key][currentLanguage] != undefined) {
                            return (
                              <option key={i} value={key}>{information?.assistanceTypes[key][currentLanguage]}</option>
                            );
                          }
                        })}
                    </select>
                  </div>
                );
              }}
            />
          </div>
          <div className="flex justify-between items-center ">
            {errors.assistance_type?.type === 'required' && <span className="error">{getContent('required_help.type_error')}</span>}
          </div>
        </>
      )}

      {help_required == 'yes' && assistance_type == 'LN' && (
        <>
          <div className="flex-1 p-2">
            <label>{getContent('required_help.language_label')}</label>
            <Controller
              name="assistance_language"
              control={control}
              rules={{
                required: (help_required == 'yes' && assistance_type == 'LN') ? true : false
              }}
              render={({ field }) => <input {...field} placeholder="English" type="text" autoComplete="off" maxLength="50" className={`${errors.assistance_language ? 'error' : ''}`}/>}
            />
          </div>
          <div className="flex justify-between items-center ">
            {errors.assistance_language?.type === 'required' && <span className="error">{getContent('validation.provide_value')}</span>}
          </div>
        </>
      )}

      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default RequiredHelpForm;
