import React from "react";

function SecondaryButton({ children, onClick, className }) {
  const styleClasses = [
    'font-GilroyExtraBold',
    'text-pvr-darkblue',
    'uppercase',
    'text-sm',
    'leading-none',
    'tracking-wider',
  ];

  return (
    <button className={`${styleClasses.join(' ')} ${className}`} onClick={onClick} type="button">
      {children}
    </button>
  );
}

export default SecondaryButton;