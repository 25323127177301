import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { getContent } from "../content";

function ComingSoon() {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(changeStep([1, 6]));
  }, []);

  const nextStep = (registered) => {
    if (!registered) {
      dispatch(updateRegistrant({
        registered: false,
      }));

      navigate("/new-registration");
    } else {
      dispatch(updateRegistrant({
        registered: true,
      }));

      navigate("/update-registration");
    }
  };


  return (
    <section className="flex flex-col justify-center h-full">
      <div className="w-full text-center">
        <h2 className="max-w-xl my-4 mx-auto">{getContent('comingsoon.heading')}</h2>
        <h2 className="max-w-xl my-4 mx-auto">{getContent('comingsoon.content')}</h2>

      </div>
    </section>
  );
}

export default ComingSoon;
