import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeStep } from "../store/settings/settingsSlice";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import BallotRequestConfirmationForm from "../Forms/BallotRequestConfirmationForm";
import BackButton from "../Components/BackButton";
import { getContent } from "../content";

function BallotRequestConfirmation() {
    const information = useSelector((state) => state.information);
    const currentLanguage = useSelector((state) => state.settings.currentLanguage);

    let navigate = useNavigate();
    const dispatch = useDispatch();

    React.useEffect(() => {
        dispatch(changeStep([5, 6]));
    }, []);

    const onSubmit = async (data) => {
        dispatch(updateRegistrant({
            ballot_request_confirmed: data.ballot_request_confirmed
        }));

        navigate('/had-assistance');
    };

    return (
        <section className="lg:grid grid-cols-12 gap-4">
            <div className="col-start-1 col-span-4">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:mt-8 lg:mt-16">{getContent('ballot_request.confirmation_heading')}</h2>
            </div>
            <div className="col-start-6 col-span-7">
                <div className="border-4 border-pvr-darkblue rounded-lg p-8" dangerouslySetInnerHTML={{ __html: information.mailinDeclaration[currentLanguage] }}></div>
                <BallotRequestConfirmationForm submit={onSubmit} />
            </div>
        </section>
    );
}

export default BallotRequestConfirmation;