import React from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../Components/BackButton";
import AddressInformationForm from "../Forms/AddressInformationForm";
import { useDispatch } from "react-redux";
import { updateRegistrant } from "../store/registrant/registrantSlice";
import { changeStep } from "../store/settings/settingsSlice";
import { getContent } from "../content";
import { useSelector } from 'react-redux';

function AddressInformation() {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const registrant = useSelector((state) => state.registrant);

    React.useEffect(() => {
        dispatch(changeStep([3, 6]));
    }, []);

    const onSubmit = (data) => {
        Object.keys(data).forEach(key => data[key] === undefined ? delete data[key] : {});

        if (data.no_address) {
            dispatch(updateRegistrant({
                no_address: data.no_address,
                address_1: '',
                address_2: '',
                address_2_type: '',
                address_2_number: '',
                city: '',
                zipcode: '',
                county: '',
                municipality: '',
            }));
            
            //navigate("/paper-application");
            navigate("/not-eligible");
        } else {

            const {
                ballot_request,
                ballot_request_address_type,
            } = registrant;
    
            let ballotRequestData = {};
            if (ballot_request === 'yes' && ballot_request_address_type === 'R') {
                ballotRequestData = {
                    ballot_request_address_1: `${data.address_1}${data.address_2_type !== '' ? ', '+data.address_2_type+' '+data.address_2_number : ''}${data.address_2 !== '' ? ', '+data.address_2 : ''}`,
                    ballot_request_city: data.city,
                    ballot_request_state: data.state,
                    ballot_request_zipcode: data.zipcode,
                }
            }

            dispatch(updateRegistrant({
                ...data,
                ...ballotRequestData,
            }));

            navigate("/party-affiliation");
        }
    };

    return (
        <section className="lg:grid grid-cols-12 gap-4">
            <div className="col-start-1 col-span-4">
                <BackButton onClick={() => navigate(-1)} />
                <h2 className="my-4 md:my-8 lg:mt-16">{getContent('address_information.heading')}</h2>
            </div>
            <div className="col-start-6 col-span-7">
                <div className="border-4 border-pvr-darkblue rounded-lg p-8">
                    <AddressInformationForm submit={onSubmit} />
                </div>
            </div>
        </section>
    );
}

export default AddressInformation;