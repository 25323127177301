import React from "react";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import { isValidZip } from '../helpers';
import InputMask from "react-input-mask";
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function MailingAddressForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const information = useSelector((state) => state.information);

  const { handleSubmit, control, formState: { errors }, setValue } = useForm({
    defaultValues: {
      mailing_address_1: '',
      mailing_city: '',
      mailing_state: '',
      mailing_zipcode: '',
    }
  });

  const onSubmit = function(data) {
    data.mailing_zipcode = data.mailing_zipcode.replace(/\-\_*$/g, '');
    submit(data);
  };

  React.useEffect(() => {
    const {
      mailing_address_1,
      mailing_city,
      mailing_state,
      mailing_zipcode,
    } = registrant;

    setValue('mailing_address_1', mailing_address_1);
    setValue('mailing_city', mailing_city);
    setValue('mailing_state', mailing_state);
    setValue('mailing_zipcode', mailing_zipcode);
  }, [registrant]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>

      <div className="flex-1 p-2">
        <label>{getContent('fields.mailing_address')}*</label>
        <Controller
          name="mailing_address_1"
          control={control}
          rules={{ required: true }}
          render={({ field }) => <input {...field} placeholder="555 Oak St." type="text" autoComplete="off" maxLength="40" className={`${errors.mailing_address_1 ? 'error' : ''}`} />}
        />
        {errors.mailing_address_1?.type === 'required' && <span className="error">{getContent('validation.mailing_address')}</span>}
      </div>

      <div className="flex">
        <div className="flex-1 p-2">
          <label>{getContent('fields.mailing_city')}*</label>
          <Controller
            name="mailing_city"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <input {...field} placeholder="City" type="text" autoComplete="off" maxLength="35" className={`${errors.mailing_city ? 'error' : ''}`} />}
          />
          {errors.mailing_city?.type === 'required' && <span className="error">{getContent('validation.mailing_city')}</span>}

        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.mailing_state')}*</label>
          <Controller
            name="mailing_state"
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              return (
                <div className={`select ${errors.mailing_state ? 'error' : ''}`} >
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                    {Object.keys(information?.states).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.states[key]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
          {errors.mailing_state?.type === 'required' && <span className="error">{getContent('validation.mailing_state')}</span>}
        </div>

      </div>

      <div className="flex-1 p-2">
        <label>{getContent('fields.mailing_zipcode')}*</label>
        <Controller
          name="mailing_zipcode"
          control={control}
          rules={{
            required: true,
            maxlength: 10,
            validate: {
              zipcode: value => isValidZip(value) || getContent('validation.mailing_zipcode')
            }
          }}
          render={({ field }) => {
            return (
              <InputMask placeholder="12345" className={`input ${errors.mailing_zipcode ? 'error' : ''}`} {...field} mask="99999-????" formatChars={{ 9: '[0-9]', '?': '[0-9]' }} beforeMaskedStateChange={({ nextState }) => { return nextState.value.replace(/\_*$/g, ''); }} />
            )
          }}
        />
        {errors.mailing_zipcode?.type === 'required' && <span className="error">{getContent('validation.zipcode')}</span>}
        {errors.mailing_zipcode?.type === 'zipcode' && <span className="error">{getContent('validation.mailing_zipcode')}</span>}
      </div>


      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.next_step')}</PrimaryButton>
      </div>
    </form>
  );
}

export default MailingAddressForm;