import React from "react";
import { Link } from "react-router-dom";
import PrimaryButton from "../Components/PrimaryButton";
import { useForm, Controller } from "react-hook-form";
import InputMask from "react-input-mask";
import { isValidName, isValidPhoneNumber, isValidAge, isValidDate, isValidEmail } from '../helpers';
import { useSelector } from 'react-redux';
import { getContent } from "../content";

function RegistrationForm({ submit }) {
  const registrant = useSelector((state) => state.registrant);
  const partner = useSelector((state) => state.partner);
  const information = useSelector((state) => state.information);
  const currentLanguage = useSelector((state) => state.settings.currentLanguage);

  const { handleSubmit, control, formState: { errors }, getValues, setValue } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      middle_name: '',
      race: '',
      sex: '',
      suffix: '',
      birthdate: '',
      email_address: '',
      email_address_optin: '',
      phone_number: '',
      phone_type: '',
      phone_number_optin: '',
      federal_state_employee: false,
    }
  });

  React.useEffect(() => {
    const {
      first_name,
      last_name,
      middle_name,
      race,
      sex,
      suffix,
      birthdate,
      email_address,
      email_address_optin,
      phone_number,
      phone_type,
      phone_number_optin,
      federal_state_employee,
    } = registrant

    setValue('first_name', first_name);
    setValue('last_name', last_name);
    setValue('middle_name', middle_name);
    setValue('race', race);
    setValue('sex', sex);
    setValue('suffix', suffix);
    setValue('birthdate', birthdate);
    setValue('email_address', email_address);
    setValue('email_address_optin', email_address_optin);
    setValue('phone_number', phone_number);
    setValue('phone_type', phone_type);
    setValue('phone_number_optin', phone_number_optin);
    setValue('federal_state_employee', federal_state_employee);
  }, [registrant]);

  const onSubmit = data => submit(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col sm:flex-row">
        <div className="flex-1 p-2">
          <label>{getContent('fields.first_name')}*</label>
          <Controller
            name="first_name"
            control={control}
            rules={{
              required: true,
              validate: {
                name: value => !value || (value && isValidName(value) || getContent('validation.first_name'))
              }
            }}
            render={({ field }) => <input {...field} placeholder="Jane" type="text" autoComplete="off" maxLength="30" className={`${errors.first_name ? 'error' : ''}`} />}
          />
          {errors.first_name?.type === 'required' && <span className="error">{getContent('validation.first_name')}</span>}
          {errors.first_name?.type === 'name' && <span className="error">{getContent('validation.first_name')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.last_name')}*</label>
          <Controller
            name="last_name"
            control={control}
            rules={{
              required: true,
              validate: {
                name: value => !value || (value && isValidName(value) || getContent('validation.last_name'))
              }
            }}
            render={({ field }) => <input {...field} placeholder="Doe" type="text" autoComplete="off" maxLength="30" className={`${errors.last_name ? 'error' : ''}`} />}
          />
          {errors.last_name?.type === 'required' && <span className="error">{getContent('validation.last_name')}</span>}
          {errors.last_name?.type === 'name' && <span className="error">{getContent('validation.last_name')}</span>}
        </div>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex-1 p-2">
          <label>{getContent('fields.middle_name')}</label>
          <Controller
            name="middle_name"
            control={control}
            rules={{
              validate: {
                name: value => !value || (value && isValidName(value) || getContent('validation.middle_name'))
              }
            }}
            render={({ field }) => <input {...field} placeholder="Henry" maxLength="30" type="text" autoComplete="off" className={`${errors.middle_name ? 'error' : ''}`} />}
          />
          {errors.middle_name?.type === 'name' && <span className="error">{getContent('validation.middle_name')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.suffix')}</label>
          <Controller
            name="suffix"
            control={control}
            render={({ field }) => {
              return (
                <div className="select">
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                    {Object.keys(information?.suffixes).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.suffixes[key][currentLanguage]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="flex-1 p-2">
        <label>{getContent('fields.birth_date')}*</label>
        <Controller
          name="birthdate"
          control={control}
          rules={{
            required: true,
            validate: {
              dob: value => (isValidDate(value) && isValidAge(value)) || 'Your birth date is required.'
            }
          }}
          render={({ field }) => {
            return (
              <InputMask placeholder="mm/dd/yyyy" className={`input ${errors.birthdate ? 'error' : ''}`} {...field} mask="99/99/9999" />
            );
          }}
        />
        {errors.birthdate?.type === 'required' && <span className="error">{getContent('validation.birth_date')}</span>}
        {errors.birthdate?.type === 'dob' && <span className="error">{getContent('validation.birth_date_valid')}</span>}
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex-1 p-2">
          <label>{getContent('fields.race')}</label>
          <Controller
            name="race"
            control={control}
            render={({ field }) => {
              return (
                <div className="select">
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                    {Object.keys(information?.races).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.races[key][currentLanguage]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
        </div>
        <div className="flex-1 p-2">
          <label>{getContent('fields.sex')}</label>
          <Controller
            name="sex"
            control={control}
            render={({ field }) => {
              return (
                <div className="select">
                  <select {...field}>
                    <option value="">{getContent('placeholders.dropdown')}</option>
                    {Object.keys(information?.genders).map((key, i) => {
                      return (
                        <option key={i} value={key}>{information?.genders[key][currentLanguage]}</option>
                      );
                    })}
                  </select>
                </div>
              );
            }}
          />
        </div>
      </div>
      <div className="flex-1 p-2">
        <label>{getContent('fields.email_address')}</label>
        <Controller
          name="email_address"
          control={control}
          rules={{
            validate: {
              email: value => !value || (value && isValidEmail(value) || getContent('validation.email_address_format'))
            }
          }}
          render={({ field }) => {
            return (
              <input {...field} placeholder="you@example.com" type="text" maxLength="50" autoComplete="off" className={`${errors.email_address ? 'error' : ''}`} />
            );
          }}
        />
        {errors.email_address?.type === 'email' && <span className="error">{getContent('validation.email_address_format')}</span>}
      </div>
      <div className="flex flex-col justify-end p-2 sm:flex-row sm:justify-between sm:items-center">
        <Controller
          name="email_address_optin"
          control={control}
          render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.email_address_optin} /><span></span> <span>{partner['email_optin_'+currentLanguage]}</span></label>}
        />
        <Link className="text-xs text-right underline" to="/privacy-policy">{getContent('general.privacy_policy')}</Link>
      </div>
      <div className="flex flex-col sm:flex-row">
        <div className="flex-1 p-2">
          <label>{getContent('fields.phone_number')}</label>
          <Controller
            name="phone_number"
            control={control}
            rules={{
              validate: {
                phone: value => !value || (value && isValidPhoneNumber(value) || getContent('validation.phone_number_valid'))
              }
            }}
            render={({ field }) => {
              return (
                <InputMask placeholder="555-555-5555" className={`input ${errors.phone_number ? 'error' : ''}`} {...field} mask="999-999-9999" />
              );
            }}
          />
          {errors.phone_number?.type === 'required' && <span className="error">{getContent('validation.phone_number_required')}</span>}
          {errors.phone_number?.type === 'phone' && <span className="error">{getContent('validation.phone_number_valid')}</span>}
        </div>
        <div className="flex-1 p-2">
          <label>&nbsp;</label>
          <Controller
            name="phone_type"
            control={control}
            rules={{
              required: !getValues('phone_number') ? false : true
            }}
            render={({ field }) => {
              return (
                <div className={`select ${errors.phone_type ? 'error' : ''}`} >
                  <select {...field}>
                    <option value="" disabled>{getContent('fields.phone_type_select_label')}</option>
                    <option value="">{getContent('fields.phone_type_select')}</option>
                    <option value="cell">{getContent('fields.cell_phone')}</option>
                    <option value="home">{getContent('fields.home_phone')}</option>
                    <option value="work">{getContent('fields.work_phone')}</option>
                  </select>
                </div>
              );
            }}
          />
          {errors.phone_type?.type === 'required' && <span className="error">{getContent('validation.phone_number_valid')}</span>}
        </div>
      </div>
      <div className="flex flex-col justify-end p-2 sm:flex-row sm:justify-between sm:items-center">
        <Controller
          name="phone_number_optin"
          control={control}
          render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.phone_number_optin} /><span></span> <span>{partner['phone_optin_'+currentLanguage]}</span></label>}
        />
        <Link className="text-xs text-right underline" to="/privacy-policy">{getContent('general.privacy_policy')}</Link>
      </div>

      {!registrant?.registered && (
        <div className="flex items-center justify-between p-2 pt-6 ">
          <Controller
            name="federal_state_employee"
            control={control}
            render={({ field }) => <label className="checkbox"><input {...field} type="checkbox" defaultChecked={registrant.federal_state_employee} /><span></span> <span>{getContent('update_information.federal_employee')}</span></label>}
          />
        </div>
      )}

      <div className="flex justify-end pt-8 pb-2">
        <PrimaryButton submit>{getContent('buttons.lets_get_started')}</PrimaryButton>
      </div>
    </form>
  );
}

export default RegistrationForm;